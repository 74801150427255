import React, { useEffect } from "react";

import { useLocation,useParams, Link } from "react-router-dom";

import PropTypes from "prop-types";
import { Helmet } from 'react-helmet'

import MKTypography from "../../components/MKTypography";
import MKBadge from "../../components/MKBadge";
import MKButton from "../../components/MKButton";
import PublicFooter from "./PublicFooter";
import MKBox from "../../components/MKBox";
import routes from "../../routes";
import siteRoutes from "../../site-routes";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import footerRoutes from "../../footer.routes";
import pageRoutes from "../../page-routes";
import FooterOne from "../sections/page-sections/footers/components/FooterOne";

function PublicLayout({ children, title, light=true,transparent=true }) {
  const { pathname } = useLocation();
  const { tenantName } = useParams();



  // useEffect(() => {
  //   setLayout(dispatch, "public");
  // }, [pathname]);

  return (
    <MKBox
      // width="98vw"
      // height="100%"
      minHeight="80vh"
      bgColor={"white"}
      // sx={{ overflowX: "hidden" }}
    >
      <Helmet>
        <title>
          CONSEN - {title || "Application Workflow Platform"}
        </title>
      </Helmet>

      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "internal",
          route: "/contact-us",
          label: "Contact us",
          color: "primary",
        }}
        transparent={transparent}
        light={light}
        // sticky
      />
      {/*<MKBox*/}
      {/*  pt={12}*/}
      {/*  // width="98vw"*/}
      {/*  // height="100%"*/}
      {/*  minHeight="60vh"*/}
      {/*  // bgColor={"white"}*/}
      {/*  // sx={{ overflowX: "hidden" }}*/}
      {/*>*/}
      {children}
      {/*</MKBox>*/}
      {/*<MKBox pt={6} px={1} mt={6}>*/}
      {/*  */}

      {/*</MKBox>*/}
      <DefaultFooter content={footerRoutes} />
    </MKBox>
  );
}

// Setting default values for the props for PublicLayout
PublicLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PublicLayout
PublicLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export const withPublicLayout = (component: React.ComponentType): React.FunctionComponent<{}> => {
  return function () {
    return <PublicLayout>{React.createElement(component)}</PublicLayout>;
  };
};

export default PublicLayout;
