import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  Grid,
  Switch,
  CircularProgress,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { debounce } from 'lodash';
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// Custom Components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";
import FormField from "components/FormField";

// Services & Constants
import CSApiService from "api-service/cs-api-service";
import { CS_LANDING_GOOGLE_SITE_KEY } from "static-data/app-constants";

// Assets
import bgImage from "assets/images/cs/CS-screens/1.png";

// Types
interface FormValues {
  name: string;
  phoneNumber: string;
  email: string;
  message: string;
}

interface AlertState {
  message: string;
  type: 'success' | 'error' | 'info';
  show: boolean;
}

const INITIAL_FORM_VALUES: FormValues = {
  name: "",
  phoneNumber: "",
  email: "",
  message: "",
};

const FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required("Full name is required")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be less than 50 characters"),
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  phoneNumber: Yup.string()
    .matches(
      /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "Please enter a valid phone number"
    )
    .nullable(),
  message: Yup.string()
    .required("Message is required")
    .min(10, "Message must be at least 10 characters")
    .max(1000, "Message must be less than 1000 characters"),
});

function ContactForm() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [alert, setAlert] = useState<AlertState>({
    message: "",
    type: "info",
    show: false
  });
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const reCaptchaRef = React.useRef<ReCAPTCHA>(null);

  const handleAlertClose = () => {
    setAlert(prev => ({ ...prev, show: false }));
  };

  const showAlert = (message: string, type: AlertState['type']) => {
    setAlert({ message, type, show: true });
    setTimeout(handleAlertClose, 5000);
  };

  // const getHTMLMessageBody = (values: FormValues) =>
  //   Object.entries(values)
  //     .map(([key, value]) => `
  //       <div style="margin-bottom: 10px;">
  //         <strong>${key}:</strong> ${value}
  //       </div>
  //     `)
  //     .join("");

  const handleSubmit = async (values: FormValues, { resetForm }) => {
    try {
      setIsSubmitting(true);

      // Verify reCAPTCHA
      // const token = await reCaptchaRef.current?.executeAsync();
      // console.log(token);
      // if (!token) {
      //   console.log("no token")
      //   throw new Error("reCAPTCHA verification failed");
      // }

      const message = {
        Subject: `CONSEN Contact Form - ${values.name}`,
        HTMLBody: `<html><body>${CSApiService.getHTMLMessageBody(values)}</body></html>`,
        TXTBody: JSON.stringify(values),
      };

      await CSApiService.sendMessage(message);

      resetForm();
      setPrivacyChecked(false);
      showAlert("Thank you for your message! We'll get back to you soon.", "success");

    } catch (error) {
      console.error("Contact form submission error:", error);
      showAlert("Unable to send message. Please try again later.", "error");
    } finally {
      setIsSubmitting(false);
      // reCaptchaRef.current?.reset();
    }
  };

  const debouncedSubmit = useCallback(
    debounce(handleSubmit, 500, { leading: true, trailing: false }),
    []
  );

  return (
    <MKBox
      component="section"
      position="relative"
      minHeight="50vh"
      borderRadius="xl"
      ml={-2}
      // ml={-2}
      mb={2}
      sx={{
        // display: "grid",
        placeItems: "center",
        overflow: { xs: "hidden", lg: "visible" },
        background: ({ functions: { rgba }, palette: { white } }) =>
          `linear-gradient(${rgba(white.main, 0.8)}, ${rgba(white.main, 0.8)})`,
        backdropFilter: "blur(30px)",
      }}
    >



        <Grid
          container
          spacing={{ xs: 0, lg: 3 }}
          sx={{ mt: { xs: 0, lg: 12 } }}
        >
          <Grid item xs={12} lg={12}>
            <MKBox
              display="flex"
              flexDirection="column"
              backgroundColor="white"
              borderRadius="xl"
              shadow="lg"
              p={{ xs: 3, sm: 6 }}
              mx="auto"
              sx={{
                backdropFilter: "saturate(200%) blur(30px)",
                backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                  rgba(white.main, 0.8),
                position: "relative",
                zIndex: 2,
              }}
            >
              <MKTypography variant="h3" textAlign="center" mb={2}>
                Contact us
              </MKTypography>

              {alert.show && (
                <MKAlert color={alert.type} dismissible onClose={handleAlertClose}>
                  <MKTypography variant="body2" color="white">
                    {alert.message}
                  </MKTypography>
                </MKAlert>
              )}

              <Formik
                initialValues={INITIAL_FORM_VALUES}
                validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={debouncedSubmit}
              >
                {({ values, errors, touched, isValid }) => (
                  <Form autoComplete="off">
                    <MKBox py={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <FormField
                            label="Full Name*"
                            name="name"
                            placeholder="e.g. John Doe"
                            error={errors.name && touched.name}
                            success={values.name.length > 0 && !errors.name}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormField
                            label="Email*"
                            name="email"
                            placeholder="e.g. john@example.com"
                            error={errors.email && touched.email}
                            success={values.email.length > 0 && !errors.email}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormField
                            label="Phone Number"
                            name="phoneNumber"
                            placeholder="e.g. (123) 456-7890"
                            error={errors.phoneNumber && touched.phoneNumber}
                            success={values.phoneNumber.length > 0 && !errors.phoneNumber}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormField
                            label="Message*"
                            name="message"
                            multiline
                            rows={4}
                            placeholder="Your message here..."
                            error={errors.message && touched.message}
                            success={values.message.length > 0 && !errors.message}
                            helperText={`${values.message.length}/1000 characters`}
                          />
                        </Grid>
                      </Grid>

                      <MKBox mt={3}>
                        <MKBox display="flex" alignItems="center" mb={2}>
                          <Switch
                            checked={privacyChecked}
                            onChange={(e) => setPrivacyChecked(e.target.checked)}
                            color="primary"
                          />
                          <MKTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            ml={1}
                            sx={{ cursor: "pointer", userSelect: "none" }}
                            onClick={() => setPrivacyChecked(!privacyChecked)}
                          >
                            I agree to the{" "}
                            <MKTypography
                              component="a"
                              href="https://aceoar.io/privacy-policy/"
                              variant="button"
                              fontWeight="bold"
                              color="info"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </MKTypography>
                          </MKTypography>
                        </MKBox>



                        <MKButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          fullWidth
                          disabled={
                            isSubmitting ||
                            !privacyChecked ||
                            !isValid
                            // ||                            !recaptchaLoaded
                          }
                          sx={{
                            height: "3.5rem",
                            transition: "all 0.2s ease-in-out",
                            "&:hover": {
                              transform: "translateY(-2px)",
                              boxShadow: "lg",
                            },
                          }}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            "Send Message"
                          )}
                        </MKButton>
                      </MKBox>
                    </MKBox>
                  </Form>
                )}
              </Formik>
            </MKBox>
          </Grid>
        </Grid>


      <ReCAPTCHA
        ref={reCaptchaRef}
        size="invisible"
        sitekey={CS_LANDING_GOOGLE_SITE_KEY}
        onChange={() => null}
        onLoad={() => setRecaptchaLoaded(true)}
      />
    </MKBox>
  );
}

export default ContactForm;