// pages/solutions/PatientIntake.js
import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState } from "react";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import { useNavigate } from "react-router-dom";

// Images (you'll need these)
import bgImage from "assets/images/cs/cs-bg-2.jpg";
import intakeFlowImage from "assets/images/cs/CS-screens/9.png";
import hipaaImage from "assets/images/bg10.jpg";
import PublicLayout from "../../../layouts/PublicLayout";

function PatientIntake() {
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PublicLayout title={"Digitize Patient Intake & Registration with CONSEN"}>


      {/* Hero Section */}
      <MKBox
        pt={16} pb={6}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={8} textAlign="center">
              <MKTypography
                variant="h1"
                color="white"
                mb={3}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Transform Patient Intake & Registration
              </MKTypography>
              <MKTypography variant="body1" color="white" mb={3}>
                Streamline patient onboarding, reduce wait times, and ensure HIPAA compliance (coming soon)
                with our digital patient intake solution. Reduce registration time by 70%.
              </MKTypography>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <MKButton variant="gradient" color="info" size="large" onClick={()=>navigate('/contact-us')}>
                    Request Demo
                  </MKButton>
                </Grid>
                {/* <Grid item> */}
                {/*   <MKButton variant="outlined" color="white" size="large"> */}
                {/*     View HIPAA Features */}
                {/*   </MKButton> */}
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      <Container sx={{ py: 6 }}>
        {/* Key Benefits Section */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} textAlign="center" mb={4}>
            <MKTypography variant="h2" mb={1}>
              Modernize Patient Registration
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Deliver a seamless digital experience while improving operational efficiency
            </MKTypography>
          </Grid>

          {[
            {
              icon: "schedule",
              title: "70% Faster Registration",
              description: "Reduce patient wait times and streamline check-in process"
            },
            {
              icon: "verified_user",
              title: "HIPAA Compliant (coming soon)",
              description: "Secure, compliant patient data collection and storage"
            },
            {
              icon: "sync",
              title: "EMR Integration",
              description: "Seamless integration with major EMR systems"
            },
            {
              icon: "description",
              title: "Application Forms",
              description: "Application forms that adapt to patient responses"
            }
          ].map(({ icon, title, description }) => (
            <Grid item xs={12} md={6} lg={3} key={title}>
              <Card
                sx={{
                  p: 3,
                  height: "100%",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "translateY(-5px)",
                  }
                }}
              >
                <Icon color="info" sx={{ fontSize: 40 }}>{icon}</Icon>
                <MKTypography variant="h5" mt={2} mb={1}>
                  {title}
                </MKTypography>
                <MKTypography variant="body2" color="text">
                  {description}
                </MKTypography>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Digital Patient Journey */}
        {/* <Grid container spacing={3} mb={6}> */}
        {/*   <Grid item xs={12} textAlign="center" mb={4}> */}
        {/*     <MKTypography variant="h3"> */}
        {/*       Digital Patient Journey */}
        {/*     </MKTypography> */}
        {/*   </Grid> */}
        {/*   <Grid item xs={12}> */}
        {/*     <Card sx={{ p: 4 }}> */}
        {/*       <Stepper orientation="vertical"> */}
        {/*         {[ */}
        {/*           { */}
        {/*             label: "Pre-Visit Registration", */}
        {/*             description: "Patients complete forms securely from home", */}
        {/*             features: [ */}
        {/*               "Demographics and insurance", */}
        {/*               "Medical history", */}
        {/*               "Digital consent forms", */}
        {/*               "Insurance card upload" */}
        {/*             ] */}
        {/*           }, */}
        {/*           { */}
        {/*             label: "Verification & Eligibility", */}
        {/*             description: "Automated insurance verification", */}
        {/*             features: [ */}
        {/*               "Real-time eligibility checks", */}
        {/*               "Coverage verification", */}
        {/*               "Prior authorization status", */}
        {/*               "Payment estimation" */}
        {/*             ] */}
        {/*           }, */}
        {/*           { */}
        {/*             label: "Check-In Process", */}
        {/*             description: "Streamlined arrival experience", */}
        {/*             features: [ */}
        {/*               "Digital check-in", */}
        {/*               "Wait time updates", */}
        {/*               "Required document collection", */}
        {/*               "Payment processing" */}
        {/*             ] */}
        {/*           }, */}
        {/*           { */}
        {/*             label: "Clinical Integration", */}
        {/*             description: "Seamless data flow to clinical systems", */}
        {/*             features: [ */}
        {/*               "EMR integration", */}
        {/*               "Clinical documentation", */}
        {/*               "Provider notifications", */}
        {/*               "Care team updates" */}
        {/*             ] */}
        {/*           } */}
        {/*         ].map(({ label, description, features }) => ( */}
        {/*           <Step key={label} expanded> */}
        {/*             <StepLabel> */}
        {/*               <MKTypography variant="h6">{label}</MKTypography> */}
        {/*             </StepLabel> */}
        {/*             <StepContent> */}
        {/*               <MKTypography variant="body2" color="text" mb={2}> */}
        {/*                 {description} */}
        {/*               </MKTypography> */}
        {/*               <Grid container spacing={1}> */}
        {/*                 {features.map((feature) => ( */}
        {/*                   <Grid item xs={12} sm={6} md={3} key={feature}> */}
        {/*                     <MKBox display="flex" alignItems="center"> */}
        {/*                       <Icon color="success" sx={{ mr: 1 }}>check_circle</Icon> */}
        {/*                       <MKTypography variant="body2"> */}
        {/*                         {feature} */}
        {/*                       </MKTypography> */}
        {/*                     </MKBox> */}
        {/*                   </Grid> */}
        {/*                 ))} */}
        {/*               </Grid> */}
        {/*             </StepContent> */}
        {/*           </Step> */}
        {/*         ))} */}
        {/*       </Stepper> */}
        {/*     </Card> */}
        {/*   </Grid> */}
        {/* </Grid> */}

        {/* HIPAA Compliance & Security */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3, height: "100%" }}>
              <MKTypography variant="h4" mb={3}>
                HIPAA Compliance & Security Ready
              </MKTypography>
              {[
                {
                  title: "Data Protection",
                  features: [
                    "End-to-end encryption",
                    "Secure data storage",
                    "Access controls",
                    "Audit logging"
                  ]
                },
                {
                  title: "Privacy Controls",
                  features: [
                    "Role-based access",
                    "PHI protection",
                    "Consent management",
                    "Data retention policies"
                  ]
                },
                {
                  title: "Security Features",
                  features: [
                    "Multi-factor authentication",
                    "Activity monitoring",
                    "Secure file transfer",
                    "Breach prevention"
                  ]
                }
              ].map(({ title, features }) => (
                <MKBox key={title} mb={3}>
                  <MKTypography variant="h6" mb={2}>
                    {title}
                  </MKTypography>
                  <Grid container spacing={2}>
                    {features.map((feature) => (
                      <Grid item xs={12} sm={6} key={feature}>
                        <MKBox display="flex" alignItems="center">
                          <Icon color="success" sx={{ mr: 1 }}>verified_user</Icon>
                          <MKTypography variant="body2">
                            {feature}
                          </MKTypography>
                        </MKBox>
                      </Grid>
                    ))}
                  </Grid>
                </MKBox>
              ))}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3, height: "100%" }}>
              <MKTypography variant="h4" mb={3}>
                Open To Integrate
              </MKTypography>
              <Tabs
                value={tabValue}
                onChange={(e, newValue) => setTabValue(newValue)}
                sx={{ mb: 3 }}
              >
                <Tab label="EMR Systems" />
                <Tab label="Payment" />
                <Tab label="Insurance" />
              </Tabs>

              <Box hidden={tabValue !== 0}>
                <Grid container spacing={2}>
                  {[
                    "Epic",
                    "Cerner",
                    "Allscripts",
                    "athenahealth",
                    "eClinicalWorks",
                    "NextGen",
                    "Custom EMR",
                    "FHIR API"
                  ].map((system) => (
                    <Grid item xs={6} key={system}>
                      <MKBox display="flex" alignItems="center">
                        <Icon color="info" sx={{ mr: 1 }}>check_circle</Icon>
                        <MKTypography variant="body2">{system}</MKTypography>
                      </MKBox>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box hidden={tabValue !== 1}>
                <Grid container spacing={2}>
                  {[
                    "Credit Card Processing",
                    "Payment Plans",
                    "HSA/FSA Cards",
                    "Digital Wallets",
                    "Automated Billing",
                    "Payment Estimation",
                    "Statement Generation",
                    "Receipt Management"
                  ].map((feature) => (
                    <Grid item xs={6} key={feature}>
                      <MKBox display="flex" alignItems="center">
                        <Icon color="info" sx={{ mr: 1 }}>check_circle</Icon>
                        <MKTypography variant="body2">{feature}</MKTypography>
                      </MKBox>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box hidden={tabValue !== 2}>
                <Grid container spacing={2}>
                  {[
                    "Eligibility Verification",
                    "Benefits Check",
                    "Prior Authorization",
                    "Claims Status",
                    "Coverage Details",
                    "Co-pay Calculation",
                    "Real-time Updates",
                    "Batch Processing"
                  ].map((feature) => (
                    <Grid item xs={6} key={feature}>
                      <MKBox display="flex" alignItems="center">
                        <Icon color="info" sx={{ mr: 1 }}>check_circle</Icon>
                        <MKTypography variant="body2">{feature}</MKTypography>
                      </MKBox>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>

        {/* Results Section */}
        <Grid container spacing={3} mb={6}>
          {[
            {
              metric: "70%",
              title: "Faster Registration",
              description: "Reduce patient wait times significantly"
            },
            {
              metric: "85%",
              title: "Paper Reduction",
              description: "Eliminate most paper-based processes"
            },
            {
              metric: "95%",
              title: "Data Accuracy",
              description: "Improve patient information accuracy"
            },
            {
              metric: "50%",
              title: "Staff Time Saved",
              description: "Reduce administrative workload"
            }
          ].map(({ metric, title, description }) => (
            <Grid item xs={12} md={3} key={title}>
              <Card sx={{ p: 3, height: "100%", textAlign: "center" }}>
                <MKTypography variant="h2" color="info">
                  {metric}
                </MKTypography>
                <MKTypography variant="h5" mt={2} mb={1}>
                  {title}
                </MKTypography>
                <MKTypography variant="body2" color="text">
                  {description}
                </MKTypography>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* CTA Section */}
        <Card
          sx={{
            p: 6,
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${hipaaImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={8}>
              <MKTypography variant="h3" mb={1}>
                Ready to Transform Patient Intake?
              </MKTypography>
              <MKTypography variant="body1" color="text">
                Join leading healthcare providers who have modernized their patient experience
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={4} display="flex" justifyContent="center">
              <MKButton variant="gradient" color="info" size="large" onClick={()=>navigate('/contact-us')}>
                Schedule Demo
              </MKButton>
            </Grid>
          </Grid>
        </Card>
      </Container>


    </PublicLayout>
  );
}

export default PatientIntake;