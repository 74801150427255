// src/components/ExitIntent/ExitIntentDialog.tsx
import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Grid, IconButton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useExitIntent } from "../../../context/ExitIntentProvider";
import ExitIntentService from "../../../api-service/exitIntent.service";
import MKAlert from "../../../components/MKAlert";
import AnalyticsService from "../../../utils/analytics";

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 16,
    padding: theme.spacing(2),
    maxWidth: 600,
    margin: theme.spacing(2)
  },
  "& .MuiDialogTitle-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3)
  }
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500]
}));

// Form validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  company: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required")
});

interface FormValues {
  name: string;
  email: string;
  company: string;
}

const initialValues: FormValues = {
  name: "",
  email: "",
  company: ""
};

interface FormStatus {
  type: "success" | "error" | "info" | null;
  message: string | null;
}

function ExitIntentDialog() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { state, hideDialog, registerConversion } = useExitIntent();
  const [openTime, setOpenTime] = useState<number>(Date.now());

  const [formStatus, setFormStatus] = useState<FormStatus>({
    type: null,
    message: null
  });

  // Track when dialog shows
  useEffect(() => {
    if (state.isVisible) {
      setOpenTime(Date.now());
      AnalyticsService.trackExitIntent.shown(
        state.triggerType || "unknown" // You'll need to add triggerType to your state
      );
    }
  }, [state.isVisible]);

  // Track when dialog closes
  const handleClose = () => {
    const timeOpen = (Date.now() - openTime) / 1000; // Convert to seconds
    AnalyticsService.trackExitIntent.closed(timeOpen);
    hideDialog();
  };

  // Track form interaction
  const handleFormFocus = () => {
    AnalyticsService.trackExitIntent.formStart();
  };


  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }) => {
    try {
      setFormStatus({ type: "info", message: "Submitting..." });

      const response = await ExitIntentService.submitForm(values);

      setFormStatus({
        type: "success",
        message: response.message
      });

      // Register conversion in exit intent context
      registerConversion();

      // Reset form
      resetForm();
      // Track successful submission
      AnalyticsService.trackExitIntent.formSubmit(true);
      // Close dialog after delay
      setTimeout(() => {
        hideDialog();
        setFormStatus({ type: null, message: null });
      }, 3000);

    } catch (error) {

      setFormStatus({
        type: "error",
        message: error instanceof Error ? error.message : "An unexpected error occurred"
      });
      // Track failed submission
      AnalyticsService.trackExitIntent.formSubmit(false);
    } finally {
      setSubmitting(false);
    }
  };
  // const handleSubmit = async (values: FormValues, { setSubmitting }) => {
  //   try {
  //     // TODO: Implement your form submission logic here
  //     console.log('Form submitted:', values);
  //
  //     // Register conversion
  //     registerConversion();
  //
  //     // Optional: Send to your API
  //     // await api.saveLeadData(values);
  //
  //   } catch (error) {
  //     console.error('Form submission error:', error);
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };

  return (
    <StyledDialog
      open={state.isVisible}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth="md"
      aria-labelledby="exit-intent-dialog-title"
    >
      <CloseButton onClick={hideDialog} aria-label="close">
        <CloseIcon />
      </CloseButton>

      <DialogContent>
        <Grid container spacing={3}>
          {/* Left side - Image/Content */}
          <Grid item xs={12} md={6}>
            <MKBox
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <Typography variant="h4" color="primary" gutterBottom>
                Wait! Before You Go...
              </Typography>
              {/*<Typography variant="h6" gutterBottom>*/}
              {/*  Get Your Free Assessment*/}
              {/*</Typography>*/}
              <Typography variant="body1" color="text.secondary" paragraph>
                Get a free assessment on streamlining your operations
                and increasing your teams productivity up to 300%.
              </Typography>
              <MKBox
                component="img"
                src="logo-wd.svg"
                alt="Free Assessment"
                my={-4}
                sx={{
                  width: "50%",
                  // height: "100%",
                  objectFit: "contain"
                  // borderTopLeftRadius: ({ borders: { borderRadius } }) => ({ xs: 0, lg: borderRadius.lg }),
                }}
              />
            </MKBox>
          </Grid>

          {/* Right side - Form */}
          <Grid item xs={12} md={6}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  isSubmitting
                }) => (
                <Form onFocus={handleFormFocus}>
                  <MKBox sx={{ p: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Get Free Assessment
                    </Typography>

                    <MKBox mb={2}>
                      <MKInput
                        type="text"
                        label="Full Name"
                        name="name"
                        fullWidth
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </MKBox>

                    <MKBox mb={2}>
                      <MKInput
                        type="email"
                        label="Business Email"
                        name="email"
                        fullWidth
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </MKBox>

                    <MKBox mb={3}>
                      <MKInput
                        type="text"
                        label="Company Name"
                        name="company"
                        fullWidth
                        value={values.company}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.company && Boolean(errors.company)}
                        helperText={touched.company && errors.company}
                      />
                    </MKBox>
                    {formStatus.message && (
                      <MKAlert
                        color={formStatus.type || "info"}
                        dismissible
                        sx={{ mt: 2 }}
                      >
                        {formStatus.message}
                      </MKAlert>
                    )}

                    <MKButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                      sx={{
                        mt: 2,
                        mb: 1,
                        height: 48
                      }}
                    >
                      {isSubmitting ? "Sending..." : "Get A Free Assessment"}
                    </MKButton>

                    <Typography
                      variant="caption"
                      color="text.secondary"
                      align="center"
                      sx={{ display: "block", mt: 1 }}
                    >
                      We respect your privacy. Unsubscribe at any time.
                    </Typography>
                  </MKBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
}

export default ExitIntentDialog;