// src/components/ExitIntent/services/exitIntent.service.ts
import CSApiService from './cs-api-service';
import { AxiosError } from 'axios';
import AnalyticsService from "../utils/analytics";

export interface ExitIntentFormData {
  name: string;
  email: string;
  company: string;
  source?: string;
  metadata?: {
    url: string;
    exitTrigger: string;
    timestamp: number;
  };
}

export interface SubmissionResponse {
  success: boolean;
  message: string;
  data?: any;
}

class ExitIntentService {
  private static submissionCache = new Map<string, number>();
  private static SUBMISSION_LIMIT = 3; // Max submissions per email in 24h
  private static COOLDOWN_PERIOD = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

  private static checkRateLimit(email: string): boolean {
    const now = Date.now();
    const lastSubmission = this.submissionCache.get(email);

    if (lastSubmission) {
      if (now - lastSubmission < this.COOLDOWN_PERIOD) {
        return false;
      }
      this.submissionCache.delete(email);
    }

    this.submissionCache.set(email, now);
    return true;
  }

  static async submitForm(formData: ExitIntentFormData): Promise<SubmissionResponse> {
    try {
      // Rate limiting check
      if (!this.checkRateLimit(formData.email)) {
        throw new Error('Please wait 24 hours before submitting another request.');
      }

      // Enrich form data with metadata
      const enrichedData = {
        ...formData,
        source: 'exit_intent',
        metadata: {
          url: window.location.href,
          exitTrigger: 'popup_form',
          timestamp: Date.now(),
        },
      };

      // Submit to backend
      const response = await CSApiService.submitExitIntentForm(enrichedData);

      // Send welcome email
      await this.sendWelcomeEmail(formData.email);

      // Track conversion
      this.trackConversion(enrichedData);

      return {
        success: true,
        message: 'Thank you! Your guide has been sent to your email.',
        data: response.data,
      };

    } catch (error) {
      console.error('Form submission error:', error);

      if (error instanceof AxiosError) {
        // Handle specific API errors
        const statusCode = error.response?.status;
        switch (statusCode) {
          case 429:
            throw new Error('Too many requests. Please try again later.');
          case 400:
            throw new Error('Invalid form data. Please check your inputs.');
          case 409:
            throw new Error('This email is already registered.');
          default:
            throw new Error('An unexpected error occurred. Please try again.');
        }
      }

      throw error;
    }
  }

  private static async sendWelcomeEmail(email: string): Promise<void> {
    try {
      await CSApiService.sendEmail({
        to: email,
        template: 'exit_intent_welcome',
        data: {
          downloadLink: 'your-guide-link',
          expiryTime: '24 hours',
        },
      });
    } catch (error) {
      console.error('Welcome email error:', error);
      // Don't throw - we don't want to fail the whole submission
    }
  }
  private static trackConversion(data: ExitIntentFormData): void {
    try {
      // Track with our Analytics Service
      AnalyticsService.trackExitIntent.conversion({
        source: data.source || 'exit_intent',
        company: data.company,
        value: 1, // You can set a custom value
      });

      // Track form submission
      AnalyticsService.trackExitIntent.formSubmit(true);

      // Custom analytics (if needed)
      CSApiService.trackEvent({
        eventName: 'exit_intent_conversion',
        properties: {
          email: data.email,
          company: data.company,
          source: data.source,
          url: data.metadata?.url,
          timestamp: data.metadata?.timestamp,
        },
      });
    } catch (error) {
      console.error('Analytics tracking error:', error);
    }
  }
  // private static trackConversion(data: ExitIntentFormData): void {
  //   try {
  //     // Google Analytics
  //     if (window.gtag) {
  //       window.gtag('event', 'exit_intent_conversion', {
  //         event_category: 'lead_generation',
  //         event_label: data.source,
  //         value: 1,
  //       });
  //     }
  //
  //     // Custom analytics
  //     CSApiService.trackEvent({
  //       eventName: 'exit_intent_conversion',
  //       properties: {
  //         email: data.email,
  //         company: data.company,
  //         source: data.source,
  //         url: data.metadata?.url,
  //         timestamp: data.metadata?.timestamp,
  //       },
  //     });
  //   } catch (error) {
  //     console.error('Analytics tracking error:', error);
  //   }
  // }

}

export default ExitIntentService;