// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";

// MKit React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import appVdo from "assets/images/cs/App-design-1.mp4";
import MKTypography from "../../../components/MKTypography";

function AppBuilderDesc() {
  return (
    <MKBox component="section" py={{ xs: 6, lg: 20 }} my={6}>
      <Container>
        <Grid container item xs={12} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}>
            <MKTypography variant="h2" fontWeight="bold" mb={0.5}
                          sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                              fontSize: size["2xl"]
                            }
                          })}
            >
              Application Builder
            </MKTypography>
            {/* <MKTypography variant="body1" color="text"> */}
            {/*   Your non-technical business users can easily map out and modify workflows, ensuring a tailored and */}
            {/*   efficient intake process */}
            {/*   that aligns with your unique business requirements. */}
            {/* </MKTypography> */}
          </Grid>
          <Grid item xs={12} lg={10} sx={{ mx: "auto" }}>
            <video
              src={appVdo}
              autoPlay
              loop
              muted
              playsInline
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain"
              }}
            >
              {/* <source src="assets/images/aff-vdo/affabi-ContentPage-rearrange.webm" type="video/webm" /> */}
              Your browser does not support the video tag.
            </video>

          </Grid>
          <Grid item xs={12} lg={10} sx={{ mx: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Intuitive Form Design"
                  description="Capture customer information seamlessly with our user-friendly form design capabilities."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Document and File Sharing"
                  description="Allow customers to share essential documents and files securely within the platform."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <MKTypography variant="h4" mt={2} mb={2} px={{ xs: 3, lg: 6 }}>
                Need assistance setting up for the first time? We're happy to do it free for you!
              </MKTypography>
            </Grid>
            {/* <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}> */}
            {/*   <Grid item xs={12} md={6}> */}
            {/*     <DefaultInfoCard */}
            {/*       icon="price_change" */}
            {/*       title="Save Time & Money" */}
            {/*       description="Creating your design from scratch with dedicated designers can be very expensive. Start with our Design System." */}
            {/*     /> */}
            {/*   </Grid> */}
            {/*   <Grid item xs={12} md={6}> */}
            {/*     <DefaultInfoCard */}
            {/*       icon="devices" */}
            {/*       title="Fully Responsive" */}
            {/*       description="Regardless of the screen size, the website content will naturally fit the given resolution." */}
            {/*     /> */}
            {/*   </Grid> */}
            {/* </Grid> */}
          </Grid>
        </Grid>

      </Container>
    </MKBox>
  );
}

export default AppBuilderDesc;
