import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import step1Img from "assets/images/cs/cs-steps/1.png";
import step2mg from "assets/images/cs/cs-steps/2.png";
import step3Img from "assets/images/cs/cs-steps/3.png";
import step4Img from "assets/images/cs/cs-steps/4.png";
import step6Img from "assets/images/cs/cs-steps/6.png";

function HowItWorks() {
  const navigate = useNavigate();
  return (
    <MKBox component="section" py={{ xs: 6, lg: 20 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={1} sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"]
              }
            })}>
              From Chaos to Clarity in 5 Simple Steps
            </MKTypography>
            <MKTypography variant="h4" mb={2}>
              Transform your complex business processes into streamlined workflows in days, not months.
              No coding required, no IT dependency, no hassle.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={4} textAlign="right">
            <MKButton variant="gradient" color="warning" onClick={()=>navigate('/contact-us')}>
              Schedule Free Demo
            </MKButton>
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={6}>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step2mg} alt="Design Workflow" width="50%" />}
              title="1. Map Your Process"
              description="Transform your existing manual process into a digital workflow in minutes. Our visual builder makes it as easy as drawing on a whiteboard."
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step1Img} alt="Build Application" width="50%" />}
              title="2. Design Your Experience"
              description="Create intuitive forms and data collection points that your customers will love. No more confusing paperwork or missing information."
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step3Img} alt="Configure Offerings" width="50%" />}
              title="3. Configure & Launch"
              description="Set up your business rules and automation triggers. Go live instantly with confidence knowing everything is working perfectly."
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step4Img} alt="Review Applications" width="50%" />}
              title="4. Automate & Monitor"
              description="Watch as tasks are automatically assigned and processed. Your team only handles exceptions while everything else runs smoothly."
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step6Img} alt="Integration" width="50%" />}
              title="5. Scale & Optimize"
              description="Connect with your existing systems and expand capabilities as needed. Continuously improve based on real-time analytics."
            />
          </Grid>
        </Grid>

        {/* Success Metrics Section */}
        <Grid container spacing={3} mt={6}>
          <Grid item xs={12} textAlign="center">
            <MKTypography variant="h3" mb={3}>
              Results?
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={4}>
            <MKBox textAlign="center">
              <MKTypography variant="h2" color="info">60%</MKTypography>
              <MKTypography variant="body1">Faster Processing Time</MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <MKBox textAlign="center">
              <MKTypography variant="h2" color="success">75%</MKTypography>
              <MKTypography variant="body1">Cost Reduction</MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <MKBox textAlign="center">
              <MKTypography variant="h2" color="warning">90%</MKTypography>
              <MKTypography variant="body1">Customer Satisfaction</MKTypography>
            </MKBox>
          </Grid>
        </Grid>

        {/* Call to Action */}
        <Grid container justifyContent="center" mt={6}>
          <Grid item xs={12} md={8} textAlign="center">
            <MKTypography variant="h4" mb={2}>
              Ready to Transform Your Operations?
            </MKTypography>
            <MKButton variant="gradient" color="info" size="large" onClick={()=>navigate('/contact-us')}>
              Start Your Free Trial
            </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default HowItWorks;