interface GAEventParams {
  action: string;
  category: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  [key: string]: any; // For custom parameters
}

// Type for GA4
interface GA4EventParams {
  [key: string]: any;
}

class AnalyticsService {
  private static isGA4(): boolean {
    return typeof window.gtag === 'function';
  }

  private static isUA(): boolean {
    return typeof window.ga === 'function';
  }

  static trackEvent(params: GAEventParams): void {
    try {
      // GA4 Tracking
      if (this.isGA4()) {
        const ga4Params: GA4EventParams = {
          event_category: params.category,
          event_label: params.label,
          value: params.value,
          non_interaction: params.nonInteraction,
          ...params, // Include any custom parameters
        };

        window.gtag('event', params.action, ga4Params);
      }

      // Universal Analytics Tracking (legacy)
      if (this.isUA()) {
        window.ga('send', 'event', {
          eventCategory: params.category,
          eventAction: params.action,
          eventLabel: params.label,
          eventValue: params.value,
          nonInteraction: params.nonInteraction,
        });
      }

      // Console log for development
      if (process.env.NODE_ENV === 'development') {
        console.log('Analytics Event:', {
          type: this.isGA4() ? 'GA4' : 'UA',
          ...params,
        });
      }
    } catch (error) {
      console.error('Analytics Error:', error);
    }
  }

  // Specific exit intent events
  static trackExitIntent = {
    shown: (trigger: string) => {
      this.trackEvent({
        action: 'exit_intent_shown',
        category: 'Exit Intent',
        label: trigger,
        nonInteraction: true,
      });
    },

    closed: (timeOpen: number) => {
      this.trackEvent({
        action: 'exit_intent_closed',
        category: 'Exit Intent',
        label: 'Modal Closed',
        value: Math.round(timeOpen), // time in seconds
      });
    },

    formStart: () => {
      this.trackEvent({
        action: 'exit_intent_form_start',
        category: 'Exit Intent',
        label: 'Form Interaction',
      });
    },

    formSubmit: (success: boolean) => {
      this.trackEvent({
        action: 'exit_intent_form_submit',
        category: 'Exit Intent',
        label: success ? 'Success' : 'Failed',
      });
    },

    conversion: (data: {
      source: string;
      value?: number;
      company?: string;
    }) => {
      this.trackEvent({
        action: 'exit_intent_conversion',
        category: 'Conversion',
        label: data.source,
        value: data.value,
        company_name: data.company, // Custom parameter for GA4
      });
    },
  };
}

export default AnalyticsService;