import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function DefaultFooter({ content }) {
  const {
    brand,
    socials,
    menus,
    copyright,
    newsletter,
    bottomLinks,
    contactInfo
  } = content;


  const FooterSection = ({ title, children }) => (
    <MKBox mb={3}>
      <MKTypography
        variant="h6"
        fontWeight="bold"
        textTransform="capitalize"
        mb={1}
        color="text"
      >
        {title}
      </MKTypography>
      {children}
    </MKBox>
  );

  const FooterLink = ({ to, href, children }) => {
    const commonProps = {
      variant: "caption",
      fontWeight: "regular",
      color: "text",
      textTransform: "capitalize",
      sx: {
        transition: "color 0.2s ease-in-out",
        display: "block",
        pb: 0.5,
        "&:hover": {
          color: "light.main"
        }
      }
    };

    return href ? (
      <MKTypography
        component="a"
        href={href}
        // textTransform="capitalize"
        target="_blank"
        rel="noreferrer"
        {...commonProps}
      >
        {children}
      </MKTypography>
    ) : (
      <MKTypography
        component={Link}
        // textTransform="capitalize"
        to={to}
        {...commonProps}
      >
        {children}
      </MKTypography>
    );
  };

  const NewsletterSection = () => (
    <FooterSection title={newsletter.title}>
      <MKTypography variant="body2" mb={2}>
        {newsletter.description}
      </MKTypography>
      <Box component="form" noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter your email"
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "background.paper"
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MKButton
              variant="gradient"
              color="info"
              fullWidth
              sx={{ height: "100%" }}
            >
              {newsletter.buttonText}
            </MKButton>
          </Grid>
        </Grid>
        <MKTypography
          variant="caption"
          color="text"
          sx={{ display: "block", mt: 1 }}
        >
          {newsletter.privacyText}
        </MKTypography>
      </Box>
    </FooterSection>
  );

  return (
    <MKBox
      component="footer"
      sx={{
        // backgroundColor: "warning.focus",
        backgroundColor: "grey.900",
        // backgroundColor: ({ functions: { rgba }, palette: { white, black } }) =>
        //   black.main,
        pt: 8,
        pb: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Brand and Newsletter Section */}
          <Grid item xs={12} md={4}>
            <MKBox mb={3}>
              <Link to={brand.route}>
                <MKBox
                  component="img"
                  src={brand.image}
                  alt={brand.name}
                  maxWidth="8rem"
                  mb={2}
                />
              </Link>
              <MKTypography variant="body2" color="text" mb={2}>
                {brand.tagline}
              </MKTypography>
              <MKBox display="flex" alignItems="center" mb={3}>
                {socials.map(({ icon, link, label }) => (
                  <MKTypography
                    key={link}
                    component="a"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    variant="h4"
                    color="dark"
                    opacity={0.8}
                    mr={2}
                    sx={{
                      transition: "opacity 0.2s ease-in-out",
                      "&:hover": { opacity: 1 }
                    }}
                    aria-label={label}
                  >
                    {icon}
                  </MKTypography>
                ))}
              </MKBox>
              {/* {!isMobile && <NewsletterSection />} */}
            </MKBox>
          </Grid>

          {/* Menu Sections */}
          {menus.map(({ name, items }) => (
            <Grid key={name} item xs={12} sm={6} md={2}>
              <FooterSection title={name}>
                <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                  {items.map((item) => (
                    <MKBox key={item.name} component="li">
                      {item.items ? (
                        <MKBox mt={1} mb={0}>
                          <MKTypography
                            variant="caption"
                            // fontWeight="bold"
                            textTransform="uppercase"
                            color="warning"
                            opacity={0.8}

                            mb={1}
                          >
                            {item.name}
                          </MKTypography>
                          {item.items.map((subItem) => (
                            <FooterLink
                              key={subItem.name}
                              to={subItem.route}
                              href={subItem.href}
                            >
                              {subItem.name}
                            </FooterLink>
                          ))}
                        </MKBox>
                      ) : (
                        <FooterLink
                          to={item.route}
                          href={item.href}
                        >
                          {item.name}
                        </FooterLink>
                      )}
                    </MKBox>
                  ))}
                </MKBox>
              </FooterSection>
            </Grid>
          ))}

          {/* /!* Newsletter Section for Mobile *!/ */}
          {/* {isMobile && ( */}
          {/*   <Grid item xs={12}> */}
          {/*     <NewsletterSection /> */}
          {/*   </Grid> */}
          {/* )} */}
        </Grid>

        {/* Bottom Section */}
        <Box mt={6}>
          <Divider />
          <Grid container spacing={2} justifyContent="space-between" mt={3}>
            <Grid item xs={12} md="auto">
              {copyright}
            </Grid>
            <Grid item xs={12} md="auto">
              <MKBox display="flex" flexWrap="wrap" justifyContent="center" gap={2}>
                {bottomLinks.map((link) => (
                  <FooterLink key={link.name} to={link.route}>
                    {link.name}
                  </FooterLink>
                ))}
              </MKBox>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </MKBox>
  );
}

DefaultFooter.propTypes = {
  content: PropTypes.shape({
    brand: PropTypes.object.isRequired,
    socials: PropTypes.arrayOf(PropTypes.object).isRequired,
    menus: PropTypes.arrayOf(PropTypes.object).isRequired,
    copyright: PropTypes.node.isRequired,
    newsletter: PropTypes.object,
    bottomLinks: PropTypes.arrayOf(PropTypes.object),
    contactInfo: PropTypes.object
  }).isRequired
};

export default DefaultFooter;