// pages/resources/ROICalculator.js
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useTheme } from "@mui/material/styles";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import PublicLayout from "../../../layouts/PublicLayout";

// Charts
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
// import bgImage from "../../../assets/images/cs-logo/cs-gray-sym.svg";
// import bgImage from "../../../assets/images/cs-logo/cs-gray-sym.svg";
import bgImage from "../../../assets/images/cs/cs-bg/vshadow/4.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function ROICalculator() {
  const navigate = useNavigate();
  const theme = useTheme();

  // Calculator State
  const [inputs, setInputs] = useState({
    monthlyApplications: 100,
    avgProcessingTime: 120, // minutes
    employeeCost: 35, // per hour
    errorRate: 15, // percentage
    complianceCost: 5000 // yearly
  });

  // Calculated Results
  const [results, setResults] = useState({
    currentCosts: 0,
    projectedSavings: 0,
    threeYearSavings: 0,
    roi: 0,
    paybackPeriod: 0
  });

  // Constants
  const CONSEN_COST = {
    setup: 5000,
    monthly: 999
  };

  const calculateROI = () => {

    // Current Costs Calculation
    const hoursPerApplication = inputs.avgProcessingTime / 60;
    const laborCostPerApplication = hoursPerApplication * inputs.employeeCost;
    const monthlyLaborCost = laborCostPerApplication * inputs.monthlyApplications;

    // Error-related costs
    const errorCostPerError = laborCostPerApplication * 2; // Assume fixing an error takes twice the time
    const monthlyErrorCost = (inputs.errorRate / 100) * inputs.monthlyApplications * errorCostPerError;

    // Monthly costs
    const currentMonthlyCost = monthlyLaborCost + monthlyErrorCost + (inputs.complianceCost / 12);
    const annualCost = currentMonthlyCost * 12;

    // CONSEN Savings
    const projectedProcessingTime = inputs.avgProcessingTime * 0.3; // 70% reduction
    const projectedErrorRate = inputs.errorRate * 0.1; // 90% reduction
    const projectedMonthlyCost = (projectedProcessingTime / 60 * inputs.employeeCost * inputs.monthlyApplications) +
      (projectedErrorRate / 100 * inputs.monthlyApplications * errorCostPerError) +
      (inputs.complianceCost * 0.2 / 12) + // 80% reduction in compliance costs
      CONSEN_COST.monthly;

    const annualSavings = annualCost - (projectedMonthlyCost * 12);
    const threeYearSavings = (annualSavings * 3) - CONSEN_COST.setup;
    const roi = ((threeYearSavings / (CONSEN_COST.setup + CONSEN_COST.monthly * 36)) * 100).toFixed(2);
    const paybackPeriod = (CONSEN_COST.setup / (annualSavings / 12)).toFixed(1);

    setResults({
      currentCosts: annualCost,
      projectedSavings: annualSavings,
      threeYearSavings,
      roi,
      paybackPeriod
    });
  };

  useEffect(() => {
    calculateROI();
  }, [inputs]);

  const chartData = {
    labels: ["Current", "Year 1", "Year 2", "Year 3"],
    datasets: [
      {
        label: "Cumulative Costs without CONSEN",
        data: [0, results.currentCosts, results.currentCosts * 2, results.currentCosts * 3],
        borderColor: theme.palette.error.main,
        tension: 0.4
      },
      {
        label: "Cumulative Costs with CONSEN",
        data: [
          CONSEN_COST.setup,
          CONSEN_COST.setup + (CONSEN_COST.monthly * 12) + (results.currentCosts - results.projectedSavings),
          CONSEN_COST.setup + (CONSEN_COST.monthly * 24) + ((results.currentCosts - results.projectedSavings) * 2),
          CONSEN_COST.setup + (CONSEN_COST.monthly * 36) + ((results.currentCosts - results.projectedSavings) * 3)
        ],
        borderColor: theme.palette.success.main,
        tension: 0.4
      }
    ]
  };

  return (
    <PublicLayout title={"Check Your ROI with CONSEN"} light={false}>
      {/* <MKBox pt={16} pb={6} minHeight="75vh"> */}
        <MKBox
          pt={16} pb={6} minHeight="85vh"
          // sx={{
          //   backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
          //     ` url(${bgImage})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center"
          // }}
        >
        <Container sx={{ py: 6 }}>

          <Grid container spacing={3}>
            <Grid item xs={12} textAlign="center" mb={4}>
              <MKTypography variant="h2" mb={1}>
                Calculate Your ROI with CONSEN
              </MKTypography>
              <MKTypography variant="body1" color="text">
                See how much you can save by automating your workflow processes
              </MKTypography>
            </Grid>
          </Grid>
          <MKBox
            px={6}
            py={6}
            // pb={10}
            mb={12}
            sx={({ borders: { borderWidth, borderColor, borderRadius } }) => ({
              // borderBottom: `${borderWidth[1]} solid ${borderColor}` ,
              // backgroundColor: ({ palette: { light, info }, functions: { rgba } }) => rgba(light.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
                ` url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center"
              // borderRadius: borderRadius.xl,
              // boxShadow: ({ boxShadows: { xxl } }) => xxl,
            })}
          >
            <Grid container spacing={3}>
              {/* <Grid item xs={12} textAlign="center" mb={4}> */}
              {/*   <MKTypography variant="h2" mb={1}> */}
              {/*     Calculate Your ROI with CONSEN */}
              {/*   </MKTypography> */}
              {/*   <MKTypography variant="body1" color="text"> */}
              {/*     See how much you can save by automating your workflow processes */}
              {/*   </MKTypography> */}
              {/* </Grid> */}

              {/* Calculator Inputs */}
              <Grid item xs={12} md={5}>
                <Card sx={{ p: 3 }}>
                  <MKTypography variant="h4" mb={3}>
                    Your Business Metrics
                  </MKTypography>

                  <MKBox mb={3}>
                    <MKTypography variant="h6" mb={1}>
                      Monthly Applications/Cases
                    </MKTypography>
                    <Slider
                      value={inputs.monthlyApplications}
                      onChange={(e, value) => setInputs(prev => ({ ...prev, monthlyApplications: value }))}
                      min={10}
                      max={1000}
                      step={10}
                      marks={[
                        { value: 10, label: "10" },
                        { value: 1000, label: "1000" }
                      ]}
                    />
                    <TextField
                      fullWidth
                      value={inputs.monthlyApplications}
                      onChange={(e) => setInputs(prev => ({ ...prev, monthlyApplications: Number(e.target.value) }))}
                      type="number"
                      size="small"
                    />
                  </MKBox>

                  <MKBox mb={3}>
                    <MKTypography variant="h6" mb={1}>
                      Average Processing Time (minutes)
                    </MKTypography>
                    <Slider
                      value={inputs.avgProcessingTime}
                      onChange={(e, value) => setInputs(prev => ({ ...prev, avgProcessingTime: value }))}
                      min={10}
                      max={480}
                      step={10}
                    />
                    <TextField
                      fullWidth
                      value={inputs.avgProcessingTime}
                      onChange={(e) => setInputs(prev => ({ ...prev, avgProcessingTime: Number(e.target.value) }))}
                      type="number"
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">min</InputAdornment>
                      }}
                    />
                  </MKBox>

                  <MKBox mb={3}>
                    <MKTypography variant="h6" mb={1}>
                      Employee Hourly Cost
                    </MKTypography>
                    <TextField
                      fullWidth
                      value={inputs.employeeCost}
                      onChange={(e) => setInputs(prev => ({ ...prev, employeeCost: Number(e.target.value) }))}
                      type="number"
                      size="small"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </MKBox>

                  <MKBox mb={3}>
                    <MKTypography variant="h6" mb={1}>
                      Current Error Rate (%)
                    </MKTypography>
                    <Slider
                      value={inputs.errorRate}
                      onChange={(e, value) => setInputs(prev => ({ ...prev, errorRate: value }))}
                      min={0}
                      max={50}
                    />
                    <TextField
                      fullWidth
                      value={inputs.errorRate}
                      onChange={(e) => setInputs(prev => ({ ...prev, errorRate: Number(e.target.value) }))}
                      type="number"
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                    />
                  </MKBox>

                  <MKBox mb={3}>
                    <MKTypography variant="h6" mb={1}>
                      Annual Compliance Costs
                    </MKTypography>
                    <TextField
                      fullWidth
                      value={inputs.complianceCost}
                      onChange={(e) => setInputs(prev => ({ ...prev, complianceCost: Number(e.target.value) }))}
                      type="number"
                      size="small"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </MKBox>
                </Card>
              </Grid>

              {/* Results */}
              <Grid item xs={12} md={7}>
                <Card sx={{ p: 3, height: "100%" }}>
                  <MKTypography variant="h4" mb={3}>
                    Your Potential Savings
                  </MKTypography>

                  <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} md={6}>
                      <MKBox bgcolor="grey.100" p={2} borderRadius="lg">
                        <MKTypography variant="h6" color="text">
                          Current Annual Costs
                        </MKTypography>
                        <MKTypography variant="h3" color="error">
                          ${results.currentCosts.toLocaleString()}
                        </MKTypography>
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKBox bgcolor="grey.100" p={2} borderRadius="lg">
                        <MKTypography variant="h6" color="text">
                          Annual Savings with CONSEN
                        </MKTypography>
                        <MKTypography variant="h3" color="success">
                          ${results.projectedSavings.toLocaleString()}
                        </MKTypography>
                      </MKBox>
                    </Grid>
                  </Grid>

                  <MKBox mb={3}>
                    <Line data={chartData} options={{ responsive: true }} />
                  </MKBox>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <MKBox textAlign="center">
                        <MKTypography variant="h6" color="text">
                          3-Year ROI
                        </MKTypography>
                        <MKTypography variant="h3" color="info">
                          {results.roi}%
                        </MKTypography>
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MKBox textAlign="center">
                        <MKTypography variant="h6" color="text">
                          Payback Period
                        </MKTypography>
                        <MKTypography variant="h3" color="info">
                          {results.paybackPeriod} months
                        </MKTypography>
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MKBox textAlign="center">
                        <MKTypography variant="h6" color="text">
                          3-Year Savings
                        </MKTypography>
                        <MKTypography variant="h3" color="info">
                          ${results.threeYearSavings.toLocaleString()}
                        </MKTypography>
                      </MKBox>
                    </Grid>
                  </Grid>

                  <Divider sx={{ my: 3 }} />

                  <MKBox textAlign="center">
                    <MKButton variant="gradient" color="info" size="large"
                              onClick={()=>navigate('/contact-us')}>
                      Get Detailed Report
                    </MKButton>
                  </MKBox>
                </Card>
              </Grid>
            </Grid>
          </MKBox>
          {/* Additional Information */}
          <MKBox
            px={6}
            pt={1}
            pb={10}
            mt={12}
            sx={({ borders: { borderWidth, borderColor, borderRadius } }) => ({
              // borderBottom: `${borderWidth[1]} solid ${borderColor}` ,
              backgroundColor: ({ palette: { light, info }, functions: { rgba } }) => rgba(light.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              borderRadius: borderRadius.xl
              // boxShadow: ({ boxShadows: { xxl } }) => xxl,
            })}
          >
            <Grid container spacing={3} mt={6}>
              <Grid item xs={12} textAlign="center" mb={4}>
                <MKTypography variant="h3">
                  How We Calculate Your Savings
                </MKTypography>
              </Grid>
              {[
                {
                  icon: "speed",
                  title: "Processing Time",
                  description: "70% reduction in processing time through automation"
                },
                {
                  icon: "error_outline",
                  title: "Error Reduction",
                  description: "90% reduction in errors and rework"
                },
                {
                  icon: "gavel",
                  title: "Compliance Costs",
                  description: "80% reduction in compliance-related expenses"
                },
                {
                  icon: "trending_up",
                  title: "Scalability",
                  description: "Handle 3x volume without adding staff"
                }
              ].map(({ icon, title, description }) => (
                <Grid item xs={12} md={3} key={title}>
                  <Card sx={{ p: 3, height: "100%", textAlign: "center" }}>
                    <Icon color="info" sx={{ fontSize: 40 }}>{icon}</Icon>
                    <MKTypography variant="h5" mt={2} mb={1}>
                      {title}
                    </MKTypography>
                    <MKTypography variant="body2" color="text">
                      {description}
                    </MKTypography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </MKBox>
        </Container>
      </MKBox>

    </PublicLayout>
  );
}

export default ROICalculator;