import React, { useState } from "react";
import {
  Card,
  Chip,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Tooltip
} from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "../../components/MKButton";
import { PRICING_PLANS } from "./components/plans";
import bgImage from "../../assets/images/cs/cs-bg/vshadow/4.png";
import {
  Check as CheckIcon,
  Info as InfoIcon,
  Star as StarIcon
} from "@mui/icons-material";
import PublicLayout from "../../layouts/PublicLayout";
import Faq from "./components/Faq";

const PricingFeature = ({ text, tooltip }) => (
  <ListItem dense>
    <ListItemIcon>
      <CheckIcon color="success" />
    </ListItemIcon>
    <ListItemText
      primary={text}
      sx={{ "& .MuiListItemText-primary": { fontSize: "0.875rem" } }}
    />
    {tooltip && (
      <Tooltip title={tooltip}>
        <IconButton size="small">
          <InfoIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    )}
  </ListItem>
);

const PricingCard = ({
                       plan, isAnnual, isHighlighted, variant = "contained",
                       color = "dark"
                     }) => {


  return (
    <Card

      sx={{ width: "100%", height: "100%", overflow: "hidden" }}
      // elevation={isHighlighted ? 8 : 1}
      // sx={({
      //        functions: { rgba, linearGradient },
      //        palette: { black, white },
      //        borders: { borderRadius, borderWidth }
      //      }) => ({
      //   height: "100%",
      //   display: "flex",
      //   flexDirection: "column",
      //   position: "relative",
      //   transition: "transform 0.2s ease-in-out",
      //   "&:hover": {
      //     transform: "translateY(-8px)"
      //   },
      //   // border: isHighlighted ? `2px solid ${theme.palette.primary.main}` : 'none',
      //   border: isHighlighted ? `${borderWidth[2]} solid ${white.main}` : "none"
      // })}
    >
      {/*{isHighlighted && (*/}
      {/*  <Chip*/}
      {/*    icon={<StarIcon />}*/}
      {/*    label="Most Popular"*/}
      {/*    color="primary"*/}
      {/*    sx={{*/}
      {/*      position: "absolute",*/}
      {/*      top: -12,*/}
      {/*      right: 20*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {/*<MKBox variant={variant} bgColor={variant === "gradient" ? color : "white"} height="100%">*/}
      {/*<CardContent sx={{ flexGrow: 1, p: 3 }}>*/}
      <MKBox variant={variant} bgColor={plan.name === "Starter" ? "white" : isHighlighted  ? "white" : "light"} height="100%">
        <MKBox p={3} lineHeight={1}>
          <MKTypography variant="h5"
                        // color={plan.name === "Starter" ? "dark" : isHighlighted  ? "primary" : "text"}
                        component="h2" gutterBottom>
            {plan.name}
          </MKTypography>
          <MKTypography variant="subtitle2" color="text" gutterBottom>
            {plan.subtitle}
          </MKTypography>

          <MKBox my={3}>
            <MKTypography variant="h3" component="div">
              {typeof plan.price === "object"
                ? isAnnual ? plan.price.annual : plan.price.monthly
                : plan.price}
            </MKTypography>
            {typeof plan.price === "object" && (
              <MKTypography variant="button" color="text.secondary">
                per {isAnnual ? "year" : "month"}
              </MKTypography>
            )}
          </MKBox>

          <MKBox my={2}>
            <MKTypography variant="subtitle2" gutterBottom>
              Includes:
            </MKTypography>
            <List>
              <PricingFeature
                text={`Up to ${plan.maxApplications} Applications`}
                tooltip="Number of distinct application forms you can create"
              />
              <PricingFeature
                text={`Up to ${plan.maxWorkflows} Workflows`}
                tooltip="Number of automated workflow processes"
              />
              <PricingFeature
                text={`${plan.maxUsers} Team Members`}
                tooltip="Number of staff users who can access the system"
              />
              <PricingFeature
                text={`${plan.storageLimit} Storage`}
                tooltip="Storage for documents and files"
              />
            </List>
          </MKBox>

          <MKBox my={3}>
            <MKTypography variant="subtitle2" gutterBottom>
              Features:
            </MKTypography>
            <List>
              {plan.features.map((feature, index) => (
                <PricingFeature key={index} text={feature} />
              ))}
            </List>
          </MKBox>

          <MKButton
            variant={plan.name === "Enterprise" ? "outlined" : "contained"}
            color={plan.name === "Starter" ? "info" : "primary"}
            fullWidth
            size="large"
            href={plan.name === "Enterprise" ? "/contact-us" : "https://app.consen.co/business-signup"}
          >
            {plan.name === "Enterprise" ? "Contact Sales" : "Get Started"}
          </MKButton>
        </MKBox>
      </MKBox>
      {/*</CardContent>*/}
      {/*</MKBox>*/}
    </Card>
  );
};

const Pricing = () => {
  const [isAnnual, setIsAnnual] = useState(true);

  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PublicLayout title={"Digital Application Intake & Workflow Solution"} light={false}>
      <MKBox
        pt={16} pb={6} minHeight="85vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            ` url(${bgImage})`,
          backgroundSize: "contain",
          backgroundPosition: "left"
        }}
      >
        <Container maxWidth="lg" sx={{ py: 8 }}>
          <MKBox textAlign="center" mb={6}>
            <MKTypography variant="h3" component="h1" gutterBottom>
              Simple, Transparent Pricing
            </MKTypography>
            <MKTypography variant="h6" color="text.secondary" gutterBottom>
              Start free, upgrade as you grow
            </MKTypography>

            <MKBox display="flex" justifyContent="center" alignItems="center" mt={3}>
              <MKTypography>Monthly</MKTypography>
              <Switch
                checked={isAnnual}
                onChange={() => setIsAnnual(!isAnnual)}
                color="primary"
              />
              <MKTypography>
                Annual
                <Chip
                  label="Save 20%"
                  size="small"
                  color="success"
                  sx={{ ml: 1 }}
                />
              </MKTypography>
            </MKBox>
          </MKBox>

          <Grid container spacing={4}>
            {Object.values(PRICING_PLANS).map((plan) => (
              <Grid item xs={12} md={4} key={plan.name}>
                <PricingCard
                  plan={plan}
                  isAnnual={isAnnual}
                  isHighlighted={plan.highlight}
                />
              </Grid>
            ))}
          </Grid>

          <MKBox mt={8} textAlign="center">
            <MKTypography variant="h5" gutterBottom>
              Enterprise Features
            </MKTypography>
            <Grid container spacing={3} justifyContent="center" mt={2}>
              {[
                "Dedicated Environment",
                "Custom SLA",
                "24/7 Support",
                "HIPAA Compliance (coming soon)",
                "On-Premise Option",
                "Custom Development"
              ].map((feature) => (
                <Grid item xs={12} sm={6} md={4} key={feature}>
                  <Card variant="outlined" sx={{ p: 2 }}>
                    <MKTypography variant="subtitle1">
                      <CheckIcon color="success" sx={{ mr: 1 }} />
                      {feature}
                    </MKTypography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </MKBox>

          <MKBox mt={8} textAlign="center">
            <MKTypography variant="h5" gutterBottom>
              Frequently Asked Questions
            </MKTypography>

            <Faq/>

          </MKBox>
        </Container>
      </MKBox>

    </PublicLayout>
  );
};

export default Pricing;