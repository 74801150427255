// pages/use-cases/CustomerOnboarding.js
import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import { useNavigate } from "react-router-dom";

// Images
import bgImage from "assets/images/cs/cs-bg-2.jpg";
import onboardingFlow from "assets/images/cs/CS-screens/9.png";
import metricsImage from "assets/images/bg10.jpg";
import PublicLayout from "../../../layouts/PublicLayout";

function CustomerOnboarding() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PublicLayout title={"Onboard Confidently with CONSEN"}>


      {/* Hero Section */}
      <MKBox
        pt={16} pb={6}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={8} textAlign="center">
              <MKTypography
                variant="h1"
                color="white"
                mb={3}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Transform Your Customer Onboarding Experience
              </MKTypography>
              <MKTypography variant="body1" color="white" mb={3}>
                Reduce onboarding time from weeks to minutes. Deliver a seamless digital experience
                that delights customers and reduces operational costs by 75%.
              </MKTypography>
              <MKButton variant="gradient" color="info" size="large" onClick={()=>navigate('/contact-us')}>
                See It In Action
              </MKButton>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      {/* Pain Points Section */}
      <Container sx={{ py: 6 }}>
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} textAlign="center" mb={4}>
            <MKTypography variant="h2" mb={1}>
              Common Onboarding Challenges
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Are these issues holding back your customer acquisition?
            </MKTypography>
          </Grid>

          {[
            {
              icon: "schedule",
              title: "Long Processing Times",
              description: "Weeks spent collecting and validating customer information manually"
            },
            {
              icon: "error_outline",
              title: "High Drop-off Rates",
              description: "Complex processes causing 40%+ customer abandonment"
            },
            {
              icon: "warning",
              title: "Compliance Risks",
              description: "Manual processes leading to documentation and regulatory issues"
            },
            {
              icon: "money_off",
              title: "High Operational Costs",
              description: "Excessive resources spent on manual data entry and verification"
            }
          ].map(({ icon, title, description }) => (
            <Grid item xs={12} md={6} lg={3} key={title}>
              <Card sx={{ p: 3, height: "100%", bgcolor: "grey.100" }}>
                <Icon color="error" sx={{ fontSize: 40 }}>{icon}</Icon>
                <MKTypography variant="h5" mt={2} mb={1}>
                  {title}
                </MKTypography>
                <MKTypography variant="body2" color="text">
                  {description}
                </MKTypography>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Solution Overview */}
        <Grid container spacing={6} alignItems="center" mb={6}>
          <Grid item xs={12} md={6}>
            <MKTypography variant="h3" mb={3}>
              Streamlined Digital Onboarding
            </MKTypography>
            <Timeline position="right">
              {[
                {
                  title: "Smart Data Collection",
                  description: "Application forms adapt to customer inputs, ensuring relevant information capture",
                  icon: "edit_note"
                },
                {
                  title: "Automated Verification",
                  description: "Real-time verification of identity, address, and documents",
                  icon: "verified_user"
                },
                {
                  title: "Intelligent Processing",
                  description: "Automated decisioning and routing based on business rules",
                  icon: "auto_awesome"
                },
                {
                  title: "Digital Documentation",
                  description: "Automated generation and execution of agreements",
                  icon: "description"
                }
              ].map(({ title, description, icon }) => (
                <TimelineItem key={title}>
                  <TimelineSeparator>
                    <TimelineDot color="info">
                      <Icon>{icon}</Icon>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <MKTypography variant="h6">{title}</MKTypography>
                    <MKTypography variant="body2" color="text">
                      {description}
                    </MKTypography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox component="img" src={onboardingFlow} width="100%" />
          </Grid>
        </Grid>

        {/* Key Benefits */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} textAlign="center" mb={4}>
            <MKTypography variant="h3">
              Measurable Results
            </MKTypography>
          </Grid>
          {[
            {
              metric: "75%",
              title: "Faster Onboarding",
              description: "Reduce onboarding time from weeks to minutes"
            },
            {
              metric: "60%",
              title: "Cost Reduction",
              description: "Lower operational costs through automation"
            },
            {
              metric: "90%",
              title: "Higher Completion",
              description: "Increase in successful onboarding completion"
            },
            {
              metric: "100%",
              title: "Compliance",
              description: "Automated regulatory compliance checks"
            }
          ].map(({ metric, title, description }) => (
            <Grid item xs={12} md={3} key={title}>
              <Card sx={{ p: 3, height: "100%", textAlign: "center" }}>
                <MKTypography variant="h2" color="info">
                  {metric}
                </MKTypography>
                <MKTypography variant="h5" mt={2} mb={1}>
                  {title}
                </MKTypography>
                <MKTypography variant="body2" color="text">
                  {description}
                </MKTypography>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Industry Examples */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} textAlign="center" mb={4}>
            <MKTypography variant="h3">
              Industry-Specific Solutions
            </MKTypography>
          </Grid>
          {[
            {
              industry: "Financial Services",
              features: [
                "KYC/AML Compliance",
                "Credit Assessment",
                "Account Opening",
                "Document Verification"
              ]
            },
            {
              industry: "Insurance",
              features: [
                "Policy Applications",
                "Risk Assessment",
                "Claims Processing",
                "Document Collection"
              ]
            },
            {
              industry: "Healthcare",
              features: [
                "Patient Registration",
                "Insurance Verification",
                "Consent Management",
                "Medical History"
              ]
            },
            {
              industry: "Professional Services",
              features: [
                "Client Intake",
                "Service Agreements",
                "Project Setup",
                "Resource Allocation"
              ]
            }
          ].map(({ industry, features }) => (
            <Grid item xs={12} md={6} lg={3} key={industry}>
              <Card sx={{ p: 3, height: "100%" }}>
                <MKTypography variant="h5" mb={2}>
                  {industry}
                </MKTypography>
                {features.map((feature) => (
                  <MKBox key={feature} display="flex" alignItems="center" mb={1}>
                    <Icon color="success" sx={{ fontSize: 20 }}>check_circle</Icon>
                    <MKTypography variant="body2" ml={1}>
                      {feature}
                    </MKTypography>
                  </MKBox>
                ))}
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* CTA Section */}
        <MKBox
          sx={{
            bgcolor: "grey.100",
            borderRadius: 2,
            p: 4,
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${metricsImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={8}>
              <MKTypography variant="h3" mb={1}>
                Ready to Transform Your Customer Onboarding?
              </MKTypography>
              <MKTypography variant="body1" color="text">
                Join leading organizations that have revolutionized their customer acquisition process
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={4} display="flex" justifyContent="center">
              <MKButton variant="gradient" color="info" size="large" onClick={()=>navigate('/contact-us')}>
                Schedule Demo
              </MKButton>
            </Grid>
          </Grid>
        </MKBox>
      </Container>


    </PublicLayout>
  );
}

export default CustomerOnboarding;