import React, { useState } from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "../../components/MKButton";
import {
  Card,
  Icon,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Tooltip,
  Collapse
} from "@mui/material";
import borders from "assets/theme/base/borders";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  const faqData = [
    {
      id: 1,
      question: "How quickly can we get started with CONSEN?",
      answer: `You can be up and running in as little as 48 hours. Our implementation process follows these steps:
        
        1. Initial setup and configuration (24-48 hours)
        2. Team onboarding and training (1-2 hours)
        3. First workflow design session (2-3 hours)
        4. Testing and validation (1-2 days)
        
        For more complex implementations or enterprise customers, we offer dedicated implementation support and custom timelines to ensure a smooth transition.`
    },
    {
      id: 2,
      question: "Do we need technical expertise to use CONSEN?",
      answer: `No technical expertise is required. CONSEN is designed for business users with:

        • Drag-and-drop workflow builder
        • Visual form designer
        • Pre-built templates and components
        • No-code automation rules
        
        While we offer API access and advanced customization options, they're entirely optional. Our platform is used successfully by non-technical teams across various industries.`
    },
    {
      id: 3,
      question: "How does CONSEN's pricing work?",
      answer: `Our pricing is transparent and based on your usage:

        • Pay only for active workflows and applications
        • No hidden fees or surprise charges
        • Scale up or down as needed
        • Annual plans save 20%
        
        All plans include essential features like workflow automation, form building, and basic integrations. Enterprise plans include additional features like dedicated environments, custom SLAs, and advanced security options.`
    },
    {
      id: 4,
      question: "What security measures does CONSEN have in place?",
      answer: `We implement enterprise-grade security measures including:

        • End-to-end encryption for all data
        • SOC 2 compliance-ready (Type I in progress)
        • Regular security audits and penetration testing
        • Role-based access control (RBAC)
        • Multi-factor authentication
        • Data encryption at rest and in transit
        
        For healthcare organizations, we offer HIPAA compliance capabilities in our Enterprise plan.`
    },
    {
      id: 5,
      question: "Can CONSEN integrate with our existing systems?",
      answer: `Yes, CONSEN offers extensive integration capabilities:

        • Pre-built connectors for popular systems (Salesforce, DocuSign, etc.)
        • REST API access for custom integrations
        • Webhook support for real-time data sync
        • Custom integration development for Enterprise clients
        • Secure data exchange protocols
        
        Our integration team can help assess your needs and recommend the best approach for your specific use case.`
    },
    {
      id: 6,
      question: "What kind of support do you provide?",
      answer: `We offer tiered support based on your plan:

        Starter Plan:
        • Email support (24-hour response time)
        • Knowledge base access
        • Community forum access

        Professional Plan:
        • Priority email support (4-hour response time)
        • Live chat support
        • Monthly check-ins
        
        Enterprise Plan:
        • 24/7 phone and email support
        • Dedicated success manager
        • Custom training sessions
        • Priority issue resolution`
    },
    {
      id: 7,
      question: "Can we migrate our existing processes to CONSEN?",
      answer: `Yes, we have a structured migration process:

        1. Process analysis and mapping
        2. Data migration planning
        3. Staged implementation
        4. Parallel running (if required)
        5. Validation and optimization
        
        Our team will work with you to ensure a smooth transition with minimal disruption to your operations. We also offer free migration support for annual Professional and Enterprise plans.`
    },
    {
      id: 8,
      question: "What happens to our data if we decide to leave CONSEN?",
      answer: `We believe in data ownership transparency:

        • All your data remains your property
        • Export your data at any time in standard formats
        • 30-day grace period after subscription ends
        • Assistance with data migration if needed
        • Secure data deletion confirmation
        
        We make it easy to both get your data in and out of CONSEN, with no vendor lock-in.`
    }
  ];

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} my={6}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                Get answers to common questions about CONSEN's platform, pricing, security, and implementation process.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
            {faqData.map((faq) => (
              <FaqCollapse
                key={faq.id}
                title={faq.question}
                open={collapse === faq.id}
                onClick={() => setCollapse(collapse === faq.id ? false : faq.id)}
              >
                {faq.answer}
              </FaqCollapse>
            ))}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
function FaqCollapse({ title, open, children, ...rest }) {
  const { borderWidth, borderColor } = borders;

  return (
    <MKBox mb={2}>
      <MKBox
        {...rest}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        sx={{ cursor: "pointer" }}
      >
        <MKTypography variant="button" fontWeight={"medium"} color={open ? "dark" : "text"} sx={{ userSelect: "none" }}>
          {title}
        </MKTypography>
        <MKBox color={open ? "dark" : "text"}>
          <Icon sx={{ fontWeight: "bold" }} fontSize="small">
            {open ? "remove" : "add"}
          </Icon>
        </MKBox>
      </MKBox>
      <Collapse timeout={400} in={open}>
        <MKBox py={2} lineHeight={1}>
          <MKTypography variant="button" color="dark" opacity={0.8} fontWeight="regular">
            {children}
          </MKTypography>
        </MKBox>
      </Collapse>
    </MKBox>
  );
}

export default Faq;