import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import MKTypography from "components/MKTypography";
import logoCT from "assets/images/cs-logo/3.svg";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "CONSEN.CO",
    image: logoCT,
    route: "/",
    tagline: "Transform Complex Operations into Seamless Workflows"
  },

  socials: [
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/showcase/91738961",
      label: "Follow us on LinkedIn"
    },
    {
      icon: <TwitterIcon />,
      // link: "https://twitter.com/ConsenHQ",
      link: "https://x.com/Consen_co",
      label: "Follow us on Twitter"
    },
    {
      icon: <EmailIcon />,
      link: "mailto:info@consen.co",
      label: "Contact us via email"
    },
  ],

  menus: [
    {
      name: "Platform",
      items: [
        {
          name: "Journey Orchestration",
          items: [
            { name: "Visual Workflow Builder", route: "/platform/workflow-builder" },
            { name: "Form & Document Builder", route: "/platform/form-builder" },
            { name: "Process Automation", route: "/platform/process-automation" },
            { name: "Task Management", route: "/platform/task-management" },
          ]
        },
        {
          name: "Data & Compliance",
          items: [
            { name: "Data Collection", route: "/platform/data-collection" },
            { name: "Document Management", route: "/platform/document-management" },
            { name: "Compliance Automation", route: "/platform/compliance-automation" },
            { name: "Security Features", route: "/platform/security" },
          ]
        },
        {
          name: "Integration & Analytics",
          items: [
            { name: "Integration Hub", route: "/platform/integration-hub" },
            { name: "API Documentation", route: "/platform/api-docs" },
            { name: "Analytics & Reporting", route: "/platform/analytics" },
          ]
        },
      ],
    },

    {
      name: "Solutions",
      items: [
        {
          name: "Financial Services",
          items: [
            { name: "Investment Management", route: "/solutions/investment-management" },
            { name: "Banking & Lending", route: "/solutions/banking-lending" },
            { name: "Insurance", route: "/solutions/insurance" },
            { name: "Wealth Management", route: "/solutions/wealth-management" },
          ]
        },
        {
          name: "Healthcare",
          items: [
            { name: "Patient Intake", route: "/solutions/patient-intake" },
            { name: "Clinical Research", route: "/solutions/clinical-research" },
            { name: "Insurance Verification", route: "/solutions/insurance-verification" },
            { name: "Healthcare Compliance", route: "/solutions/healthcare-compliance" },
          ]
        },
        {
          name: "Professional Services",
          items: [
            { name: "Legal Services", route: "/solutions/legal-services" },
            { name: "Consulting", route: "/solutions/consulting" },
            { name: "Real Estate", route: "/solutions/real-estate" },
          ]
        },
      ],
    },

    {
      name: "Resources",
      items: [
        // { name: "Documentation", route: "/resources/documentation" },
        { name: "Implementation Guide", route: "/resources/implementation-guide" },
        { name: "API Reference", route: "/resources/api-reference" },
        // { name: "Knowledge Base", route: "/resources/knowledge-base" },
        { name: "Case Studies", route: "/resources/case-studies" },
        { name: "ROI Calculator", route: "/resources/roi-calculator" },
        // { name: "Blog", route: "/resources/blog" },
      ],
    },

    {
      name: "Company",
      items: [
        { name: "About Us", href: "https://aceoar.io" },
        { name: "Contact Us", route: "/contact-us" },
        { name: "Partners", route: "/contact-us" },
        { name: "Careers", route: "/contact-us" },
        { name: "News", route: "/contact-us" },
      ],
    },

    // {
    //   name: "Legal",
    //   items: [
    //     { name: "Terms of Service", route: "/legal/terms" },
    //     { name: "Privacy Policy", route: "/legal/privacy" },
    //     { name: "Security Policy", route: "/legal/security" },
    //     { name: "Acceptable Use", route: "/legal/acceptable-use" },
    //     { name: "GDPR Compliance", route: "/legal/gdpr" },
    //   ],
    // },
  ],

  bottomLinks: [
    { name: "Status", route: "https://app.consen.co/status" },
    { name: "Security", route: "/security" },
    { name: "Terms", route: "/legal/terms" },
    { name: "Privacy", route: "/legal/privacy" },
    { name: "Cookies", route: "/legal/cookies" },
  ],

  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Copyright © {date}{" "}
      <MKTypography
        component="a"
        href="https://www.consen.co"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Consen.co
      </MKTypography>
      {" "}by{" "}
      <MKTypography
        component="a"
        href="https://www.aceoar.io"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Ace|Oar Technologies
      </MKTypography>
      . All rights reserved.
    </MKTypography>
  ),

  // New additions for enhanced footer functionality
  newsletter: {
    title: "Stay Updated",
    description: "Get the latest updates on product features and industry insights.",
    buttonText: "Subscribe",
    privacyText: "We respect your privacy. Unsubscribe at any time.",
  },

  contactInfo: {
    email: "info@consen.co",
    sales: "info@consen.co",
    support: "info@consen.co",
  },

  certifications: [
    // Add certification logos/badges when available
    // { name: "SOC2", image: soc2Logo },
    // { name: "GDPR", image: gdprLogo },
  ],

  // Additional metadata for SEO and tracking
  metadata: {
    companyName: "Consen",
    legalName: "Ace|Oar Technologies Inc.",
    founded: "2023",
    industry: "Enterprise Software",
    description: "Enterprise workflow automation platform",
  }
};