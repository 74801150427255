// @mui material components
import { Box, Card, Container, Divider, Grid } from "@mui/material";
// MKit React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// MKit React examples
// HelpCenter page sections
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import HandshakeIcon from "@mui/icons-material/Handshake";
// Routes
// import routes from "routes";
// Images
import bgImage from "assets/images/cs-bg/5.png";
import PublicLayout from "../../layouts/PublicLayout";
import ContactForm from "../common-sections/ContactForm";
import ServiceHighlights from "./sections/ServiceHighlights";

function ContactCard({ icon, title, description, action }) {
  return (
    <Card
      sx={{
        p: 3,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        transition: "transform 0.3s",
        "&:hover": {
          transform: "translateY(-5px)"
        }
      }}
    >
      <Box
        sx={{
          width: 48,
          height: 48,
          // borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor: "primary.light",
          color: "primary.main",
          mb: 2
        }}
      >
        {icon}
      </Box>
      <MKTypography variant="h6" mb={1}>
        {title}
      </MKTypography>
      <MKTypography
        variant="body2"
        color="text"
        mb={2}
        flex={1}
      >
        {description}
      </MKTypography>
      {/* {action && ( */}
      {/*   <MKButton */}
      {/*     variant="outlined" */}
      {/*     color="info" */}
      {/*     size="small" */}
      {/*     component="a" */}
      {/*     href={action.link} */}
      {/*     target="_blank" */}
      {/*   > */}
      {/*     {action.label} */}
      {/*   </MKButton> */}
      {/* )} */}
    </Card>
  );
}


function ContactUs() {

  const contactOptions = [
    {
      icon: <EmailIcon fontSize="medium" />,
      title: "General Inquiries",
      description: "Have questions about CONSEN? Our team is here to help you find the right solution for your business.",
      action: {
        label: "Email Us",
        link: "mailto:info@consen.co"
      }
    },
    {
      icon: <BusinessIcon fontSize="medium" />,
      title: "Enterprise Solutions",
      description: "Looking for enterprise-grade customer onboarding solutions? Let's discuss your specific requirements.",
      action: {
        label: "Schedule a Call",
        link: "/schedule-demo"
      }
    },
    {
      icon: <HandshakeIcon fontSize="medium" />,
      title: "Partnership Opportunities",
      description: "Interested in partnering with CONSEN? Explore our partnership programs and growth opportunities.",
      action: {
        label: "Partner With Us",
        link: "/partners"
      }
    },
    {
      icon: <SupportAgentIcon fontSize="medium" />,
      title: "Technical Support",
      description: "Existing customer? Our support team is available 24/7 to assist you with any technical questions.",
      action: {
        label: "Get Support",
        link: "/support"
      }
    }
  ];

  return (
    <PublicLayout title={"Contact Us"}>
      {/* <DefaultNavbar */}
      {/*   routes={routes} */}
      {/*   action={{ */}
      {/*     type: "external", */}
      {/*     route: "https://www.creative-tim.com/product/material-kit-pro-react", */}
      {/*     label: "buy now", */}
      {/*     color: "info", */}
      {/*   }} */}
      {/*   transparent */}
      {/*   light */}
      {/* /> */}
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center"
        }}
      >
        <Container>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={12} md={8}>
              <MKTypography
                variant="h1"
                color="white"
                mb={1}
                sx={({ typography: { size } }) => ({
                  fontSize: size["3xl"]
                })}
              >
                Let's Connect
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                opacity={0.8}
                mb={3}
              >
                Transform your customer onboarding experience with CONSEN
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden"
        }}
      >

        {/* <Faq /> */}


        <Container>

          <MKBox>
            <Grid container spacing={4} mb={6}>
              {contactOptions.map((option, index) => (
                <Grid item xs={12} md={6} lg={3} key={index}>
                  <ContactCard {...option} />
                </Grid>
              ))}
            </Grid>

          </MKBox>
          <Divider sx={{ my: 6 }} />
          <MKBox mt={-16}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={5}>
                <MKTypography variant="h3" mb={1}>
                  Get in Touch
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={3}>
                  Fill out the form and our team will get back to you within 24 hours.
                </MKTypography>

                <MKBox mb={2}>
                  <MKTypography variant="body1" fontWeight="bold">
                    Location
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    3300 Highway 7<br />
                    Suite 600<br />
                    Vaughan, Ontario, Canada, L4K 4M3
                  </MKTypography>
                </MKBox>

                <MKBox mb={2}>
                  <MKTypography variant="body1" fontWeight="bold">
                    Business Hours
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Monday - Friday: 9:00 AM - 5:00 PM EST<br />
                    Saturday - Sunday: Closed
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={7}>
                <ContactForm />
              </Grid>

            </Grid>
          </MKBox>
        </Container>

      </Card>
      {/* <MKBox pt={6} px={1} mt={6}> */}
      <ServiceHighlights />
      {/* <Features /> */}
      {/* </MKBox> */}
    </PublicLayout>
  );
}

export default ContactUs;
