// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function TechnicalCapabilities() {
  return (
    <MKBox component="section" py={{ xs: 6, lg: 12 }} bgcolor="grey.100">
      <Container>
        <Grid container alignItems="center" mb={4}>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h3" mb={1}>
              Enterprise-Grade Architecture, Startup Agility
            </MKTypography>
            <MKTypography variant="body1" color="text" mb={2}>
              Built with scalability, security, and flexibility at its core
            </MKTypography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {/* Security & Privacy */}
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="security"
              color="error"
              title="Security First Design"
              description={
                <>
                  • End-to-end encryption
                  <br />
                  • Role-based access control
                  <br />
                  • Multi-tenant architecture
                  <br />
                  • Regular security audits
                </>
              }
            />
          </Grid>

          {/* Infrastructure */}
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="cloud_done"
              color="info"
              title="Modern Infrastructure"
              description={
                <>
                  • Cloud-native architecture
                  <br />
                  • Auto-scaling capabilities
                  <br />
                  • 99.9% targeted uptime
                  <br />
                  • Continuous monitoring
                </>
              }
            />
          </Grid>

          {/* Integration */}
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="integration_instructions"
              color="success"
              title="Integration Ready"
              description={
                <>
                  • RESTful APIs
                  <br />
                  • Webhook support
                  <br />
                  • Custom connectors
                  <br />
                  • OAuth 2.0 authentication
                </>
              }
            />
          </Grid>

          {/* Data Management */}
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="database"
              color="warning"
              title="Data Management"
              description={
                <>
                  • Automated backups
                  <br />
                  • Data encryption at rest
                  <br />
                  • Flexible storage options
                  <br />
                  • Data export capabilities
                </>
              }
            />
          </Grid>

          {/* Development */}
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="code"
              color="primary"
              title="Development Standards"
              description={
                <>
                  • Modern tech stack
                  <br />
                  • Continuous deployment
                  <br />
                  • Version control
                  <br />
                  • Automated testing
                </>
              }
            />
          </Grid>

          {/* Support */}
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="support_agent"
              color="info"
              title="Dedicated Support"
              description={
                <>
                  • Technical documentation
                  <br />
                  • Implementation assistance
                  <br />
                  • Email support
                  <br />
                  • Regular updates
                </>
              }
            />
          </Grid>
        </Grid>

        {/* Roadmap Section */}
        <Grid container spacing={3} mt={6}>
          <Grid item xs={12}>
            <MKBox textAlign="center">
              <MKTypography variant="h4" mb={2}>
                Security & Compliance Roadmap
              </MKTypography>
              <MKTypography variant="body1" color="text">
                We're committed to achieving the highest standards of security and compliance
              </MKTypography>
            </MKBox>
          </Grid>

          <Grid item xs={12} md={4}>
            <MKBox textAlign="center" p={3}>
              <MKTypography variant="h6" color="info">
                In Progress
              </MKTypography>
              <MKTypography variant="body2">
                • SOC 2 Type I Assessment
                <br />
                • GDPR Compliance Program
                <br />
                • Enhanced Encryption Standards
              </MKTypography>
            </MKBox>
          </Grid>

          <Grid item xs={12} md={4}>
            <MKBox textAlign="center" p={3}>
              <MKTypography variant="h6" color="warning">
                Coming Soon
              </MKTypography>
              <MKTypography variant="body2">
                • ISO 27001 Certification
                <br />
                • HIPAA Compliance
                <br />
                • SOC 2 Type II Audit
              </MKTypography>
            </MKBox>
          </Grid>

          <Grid item xs={12} md={4}>
            <MKBox textAlign="center" p={3}>
              <MKTypography variant="h6" color="success">
                Future Plans
              </MKTypography>
              <MKTypography variant="body2">
                • FedRAMP Authorization
                <br />
                • PCI DSS Certification
                <br />
                • Additional Regional Compliance
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>

        {/* Trust Banner */}
        <Grid container mt={6} textAlign="center">
          <Grid item xs={12}>
            <MKBox p={3} bgcolor="white" borderRadius="lg" shadow="md">
              <MKTypography variant="h5" mb={1}>
                Your Security is Our Priority
              </MKTypography>
              <MKTypography variant="body2" color="text">
                While we're actively working towards formal certifications, our platform is built on
                enterprise-grade security principles and best practices. We maintain transparency about
                our security journey and welcome detailed technical discussions about our architecture
                and security measures.
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default TechnicalCapabilities;