import PlatformOverview from "./pages/platform/overview";
import HomePage from "./pages/HomePage";
import Icon from "./assets/theme/components/icon";
import AboutUs from "./pages/Company/AboutUs";
import HelpCenter from "./pages/Support/HelpCenter";
import ContactUs from "./pages/ContactUs";
import CookiePolicy from "./pages/legal/CookiePolicy";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import TermsOfUse from "./pages/legal/TermsOfUse";
import LandingPage from "./pages/Landing";
import ProcessAutomation from "./pages/platform/process-automation";
import CustomerOnboarding from "./pages/use-cases/customer-onboarding";
import WorkflowAutomation from "./pages/use-cases/workflow-automation";
import ROICalculator from "./pages/resources/roi-calculator";
import ClinicalResearch from "./pages/solutions/clinical-research";
import PatientIntake from "./pages/solutions/patient-intake";
import Pricing from "./pages/pricing";

const siteRoutes = [
  {
    name: "Landing",
    key: "landing",
    route: "/home",
    component: <LandingPage/>
  },

  {
    name: "Pricing",
    key: "pricing",
    route: "/pricing",
    component: <Pricing/>
  },
  {
    name: "Platform Overview",
    key: "platform-overview",
    route: "/platform/overview",
    component: <PlatformOverview/>
  },
  {
    name: "Process Automation",
    key: "platform-process-automation",
    route: "/platform/process-automation",
    component: <ProcessAutomation/>
  },



  {
    name: "Patient Intake",
    key: "solutions-patient-intake",
    route: "/solutions/patient-intake",
    component: <PatientIntake/>
  },
  {
    name: "Clinical Research",
    key: "solutions-clinical-research",
    route: "/solutions/clinical-research",
    component: <ClinicalResearch/>
  },


  {
    name: "Customer Onboarding",
    key: "use-cases-customer-onboarding",
    route: "/use-cases/customer-onboarding",
    component: <CustomerOnboarding/>
  },
  {
    name: "Workflow Automation",
    key: "use-cases-workflow-automation",
    route: "/use-cases/workflow-automation",
    component: <WorkflowAutomation/>
  },
  {
    name: "ROI Calculator",
    key: "resources-roi-calculator",
    route: "/resources/roi-calculator",
    component: <ROICalculator/>
  },

  {
    name: "Contact Us",
    key: "contact-us",
    route: "/contact-us",
    component: <ContactUs/>
  },
  {
    name: "Cookie Policy",
    key: "CP",
    route: "/CookiePolicy",
    component: <CookiePolicy/>,
  },
  {
    name: "Privacy Policy",
    key: "PP",
    route: "/PrivacyPolicy",
    component: <PrivacyPolicy/>,
  },
  {
    name: "Terms of use",
    key: "TU",
    route: "/TermsOfUse",
    component: <TermsOfUse/>,
  },
];

export default siteRoutes;
