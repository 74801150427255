export const PRICING_PLANS = {
  STARTER: {
    name: "Starter",
    subtitle: "For small teams getting started",
    price: {
      monthly: "$299",
      annual: "$2,990", // 2 months free
    },
    highlight: false,
    maxApplications: 5,
    maxWorkflows: 3,
    maxUsers: 5,
    storageLimit: "10GB",
    features: [
      "Application Builder ",
      "Visual Workflow Builder",
      "Custom Branding",
      "Basic Integrations",
      "Email Support",
      "99.9% Uptime SLA",
      "Basic Analytics"
    ]
  },
  PROFESSIONAL: {
    name: "Professional",
    subtitle: "For growing businesses",
    price: {
      monthly: "$799",
      annual: "$7,990",
    },
    highlight: true,
    maxApplications: 20,
    maxWorkflows: 10,
    maxUsers: 20,
    storageLimit: "50GB",
    features: [
      "Everything in Starter, plus:",
      "Advanced Workflow Rules",
      "Document Generation",
      "API Access",
      "Priority Support",
      "Advanced Analytics",
      "Audit Logs",
      "Custom Integrations",
      "SSO Authentication"
    ]
  },
  ENTERPRISE: {
    name: "Enterprise",
    subtitle: "For large organizations",
    price: {
      monthly: "Contact Us",
      annual: "Contact Us",
    },
    highlight: false,
    maxApplications: "Unlimited",
    maxWorkflows: "Unlimited",
    maxUsers: "Unlimited",
    storageLimit: "Custom",
    features: [
      "Everything in Professional, plus:",
      "Dedicated Environment",
      "Custom SLA",
      "24/7 Support",
      "Dedicated Success Manager",
      "Custom Development",
      "On-Premise Option",
      "Compliance ",
      "Advanced Security Features"
    ]
  }
};