// pages/platform/ProcessAutomation.js
import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { useNavigate } from "react-router-dom";
// Custom components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
// import routes from "routes";
// import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/cs/cs-bg-2.jpg";
import automationFlow from "assets/images/cs/CS-screens/9.png";
import processMetrics from "assets/images/bg10.jpg";
import PublicLayout from "../../../layouts/PublicLayout";

function ProcessAutomation() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PublicLayout title={"Digital Application Intake & Workflow Solution"}>
      {/* <DefaultNavbar routes={routes} sticky /> */}

      {/* Hero Section */}
      <MKBox
        minHeight="75vh"
        width="100%"
        pt={12}
        pb={6}
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={8} lg={7} textAlign="center">
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Transform Manual Processes into Automated Workflows
              </MKTypography>
              <MKTypography variant="body1" color="white" mt={1} mb={3}>
                Reduce processing time by 60% and eliminate human errors with intelligent process automation
              </MKTypography>
              <MKButton variant="gradient" color="info" size="large" onClick={()=>navigate('/contact-us')}>
                Schedule Demo
              </MKButton>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      {/* Main Content */}
      <Container sx={{ py: 6 }}>
        {/* Key Benefits Section */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} textAlign="center" mb={4}>
            <MKTypography variant="h2" mb={1}>
              Automate Any Business Process
            </MKTypography>
            <MKTypography variant="body1" color="text">
              From simple tasks to complex workflows, automate everything without writing code
            </MKTypography>
          </Grid>

          {[
            {
              icon: "schedule",
              title: "60% Faster Processing",
              description: "Automate repetitive tasks and reduce processing time dramatically"
            },
            {
              icon: "auto_awesome",
              title: "Zero Errors",
              description: "Eliminate human errors with standardized automated processes"
            },
            {
              icon: "trending_up",
              title: "90% Cost Reduction",
              description: "Reduce operational costs by automating manual processes"
            },
            {
              icon: "insights",
              title: "Real-time Visibility",
              description: "Track and optimize processes with comprehensive analytics"
            }
          ].map(({ icon, title, description }) => (
            <Grid item xs={12} md={6} lg={3} key={title}>
              <Card sx={{ p: 2, height: "100%", textAlign: "center" }}>
                <Icon color="info" sx={{ fontSize: 40 }}>{icon}</Icon>
                <MKTypography variant="h5" mt={2} mb={1}>
                  {title}
                </MKTypography>
                <MKTypography variant="body2" color="text">
                  {description}
                </MKTypography>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* How It Works Section */}
        <Grid container spacing={6} alignItems="center" mb={6}>
          <Grid item xs={12} md={6}>
            <MKTypography variant="h3" mb={2}>
              Intelligent Process Automation
            </MKTypography>
            <MKTypography variant="body1" color="text" mb={2}>
              Our intelligent process automation combines workflow automation, document processing, and
              decision management to transform your operations.
            </MKTypography>
            <Grid container spacing={2}>
              {[
                {
                  title: "Visual Process Designer",
                  description: "Design complex processes with drag-and-drop simplicity"
                },
                {
                  title: "Smart Routing",
                  description: "Automatically route tasks based on predefined rules"
                },
                {
                  title: "Document Processing",
                  description: "Extract and process data from documents automatically"
                },
                {
                  title: "Integration Ready",
                  description: "Connect with your existing systems seamlessly"
                }
              ].map(({ title, description }) => (
                <Grid item xs={12} key={title}>
                  <MKBox display="flex" alignItems="center" p={2} bgcolor="grey.100" borderRadius="lg">
                    <Icon color="info">check_circle</Icon>
                    <MKBox ml={2}>
                      <MKTypography variant="h6">{title}</MKTypography>
                      <MKTypography variant="body2" color="text">
                        {description}
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox component="img" src={automationFlow} width="100%" />
          </Grid>
        </Grid>

        {/* Use Cases Section */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} textAlign="center" mb={4}>
            <MKTypography variant="h3">
              Process Automation Use Cases
            </MKTypography>
          </Grid>
          {[
            {
              icon: "account_balance",
              title: "Financial Services",
              items: ["Loan Processing", "Account Opening", "KYC Verification", "Claims Processing"]
            },
            {
              icon: "health_and_safety",
              title: "Healthcare",
              items: ["Patient Onboarding", "Insurance Verification", "Medical Records", "Appointments"]
            },
            {
              icon: "gavel",
              title: "Legal",
              items: ["Contract Review", "Case Management", "Document Processing", "Client Intake"]
            },
            {
              icon: "business",
              title: "Enterprise",
              items: ["Employee Onboarding", "Vendor Management", "Approval Workflows", "Compliance"]
            }
          ].map(({ icon, title, items }) => (
            <Grid item xs={12} md={6} lg={3} key={title}>
              <Card sx={{ p: 3, height: "100%" }}>
                <Icon color="info" sx={{ fontSize: 40 }}>{icon}</Icon>
                <MKTypography variant="h5" mt={2} mb={2}>
                  {title}
                </MKTypography>
                {items.map((item) => (
                  <MKBox key={item} display="flex" alignItems="center" mb={1}>
                    <Icon color="success" sx={{ fontSize: 20 }}>check</Icon>
                    <MKTypography variant="body2" ml={1}>
                      {item}
                    </MKTypography>
                  </MKBox>
                ))}
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* CTA Section */}
        <Grid container sx={{ bgcolor: "grey.100", borderRadius: 2, p: 4 }} spacing={3}>
          <Grid item xs={12} md={8}>
            <MKTypography variant="h4">
              Ready to Automate Your Processes?
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Join leading organizations that have transformed their operations with Consen
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={4} display="flex" alignItems="center" justifyContent="center">
            <MKButton variant="gradient" color="info" size="large">
              Get Started
            </MKButton>
          </Grid>
        </Grid>
      </Container>

      {/* <DefaultFooter content={footerRoutes} /> */}
    </PublicLayout>
  );
}

export default ProcessAutomation;