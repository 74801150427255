import Icon from "@mui/material/Icon";

const pageRoutes = [
  {
    name: "Platform",
    icon: <Icon>dashboard</Icon>,
    columns: 3,
    rowsPerColumn: 1,
    collapse: [
      {
        name: "Journey Orchestration",
        icon: <Icon>route</Icon>,
        collapse: [
          { name: "Platform Overview", route: "/platform/overview" },
          { name: "Visual Workflow Builder", route: "/platform/workflow-builder" },
          { name: "Form & Document Builder", route: "/platform/form-builder" },
          { name: "Process Automation", route: "/platform/process-automation" },
          { name: "Task Management", route: "/platform/task-management" },
        ],
      },
      {
        name: "Data & Compliance",
        icon: <Icon>security</Icon>,
        collapse: [
          { name: "Data Collection", route: "/platform/data-collection" },
          { name: "Document Management", route: "/platform/document-management" },
          { name: "Compliance Automation", route: "/platform/compliance-automation" },
          { name: "Security Features", route: "/platform/security" },
        ],
      },
      {
        name: "Integration & Analytics",
        icon: <Icon>integration_instructions</Icon>,
        collapse: [
          { name: "Integration Hub", route: "/platform/integration-hub" },
          { name: "API Documentation", route: "/platform/api-docs" },
          { name: "Analytics & Reporting", route: "/platform/analytics" },
          { name: "Custom Connectors", route: "/platform/connectors" },
        ],
      },
    ],
  },
  {
    name: "Solutions",
    icon: <Icon>business_center</Icon>,
    columns: 3,
    rowsPerColumn: 1,
    collapse: [
      {
        name: "Financial Services",
        icon: <Icon>account_balance</Icon>,
        collapse: [
          { name: "Investment Management", route: "/solutions/investment-management" },
          { name: "Banking & Lending", route: "/solutions/banking-lending" },
          { name: "Insurance", route: "/solutions/insurance" },
          { name: "Wealth Management", route: "/solutions/wealth-management" },
        ],
      },
      {
        name: "Healthcare & Life Sciences",
        icon: <Icon>local_hospital</Icon>,
        collapse: [
          { name: "Patient Intake", route: "/solutions/patient-intake" },
          { name: "Clinical Research", route: "/solutions/clinical-research" },
          { name: "Insurance Verification", route: "/solutions/insurance-verification" },
          { name: "Healthcare Compliance", route: "/solutions/healthcare-compliance" },
        ],
      },
      {
        name: "Professional Services",
        icon: <Icon>business</Icon>,
        collapse: [
          { name: "Legal Services", route: "/solutions/legal-services" },
          { name: "Consulting", route: "/solutions/consulting" },
          { name: "Accounting", route: "/solutions/accounting" },
          { name: "Real Estate", route: "/solutions/real-estate" },
        ],
      },
    ],
  },
  {
    name: "Use Cases",
    icon: <Icon>cases</Icon>,
    columns: 2,
    rowsPerColumn: 1,
    collapse: [
      {
        name: "Popular Use Cases",
        icon: <Icon>star</Icon>,
        collapse: [
          { name: "Customer Onboarding", route: "/use-cases/customer-onboarding" },
          { name: "Document Processing", route: "/use-cases/document-processing" },
          { name: "Compliance Management", route: "/use-cases/compliance-management" },
          { name: "Case Management", route: "/use-cases/case-management" },
        ],
      },
      {
        name: "Digital Transformation",
        icon: <Icon>transform</Icon>,
        collapse: [
          { name: "Application Intake", route: "/use-cases/application-intake" },
          { name: "Workflow Automation", route: "/use-cases/workflow-automation" },
          { name: "Legacy Modernization", route: "/use-cases/legacy-modernization" },
          { name: "Process Optimization", route: "/use-cases/process-optimization" },
        ],
      },
    ],
  },
  {
    name: "Resources",
    icon: <Icon>library_books</Icon>,
    columns: 2,
    rowsPerColumn: 1,
    collapse: [
      {
        name: "Documentation",
        icon: <Icon>description</Icon>,
        collapse: [
          { name: "Getting Started", icon: "description",route: "/resources/getting-started" },
          { name: "Implementation Guide", route: "/resources/implementation" },
          { name: "API Reference", route: "/resources/api" },
          { name: "Knowledge Base", route: "/resources/knowledge-base" },
        ],
      },
      {
        name: "Success",
        icon: <Icon>emoji_events</Icon>,
        collapse: [
          { name: "Case Studies", route: "/resources/case-studies" },
          { name: "ROI Calculator", route: "/resources/roi-calculator" },
          { name: "Success Stories", route: "/resources/success-stories" },
        ],
      },
    ],
  },
];

export default pageRoutes;