// pages/solutions/ClinicalResearch.js
import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import { useNavigate } from "react-router-dom";

// Images (you'll need these)
import bgImage from "assets/images/cs/cs-bg-2.jpg";
import workflowImage from "assets/images/cs/CS-screens/9.png";
import complianceImage from "assets/images/bg10.jpg";
import PublicLayout from "../../../layouts/PublicLayout";

function ClinicalResearch() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PublicLayout title={"Streamline Clinical Research with CONSEN"}>

      {/* Hero Section */}
      <MKBox
        pt={16} pb={6}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={8} textAlign="center">
              <MKTypography
                variant="h1"
                color="white"
                mb={3}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Streamline Clinical Research Operations
              </MKTypography>
              <MKTypography variant="body1" color="white" mb={3}>
                Accelerate participant enrollment, ensure compliance, and manage clinical trials
                with our comprehensive digital platform. Reduce study startup time by 60%.
              </MKTypography>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <MKButton variant="gradient" color="info" size="large" onClick={()=>navigate('/contact-us')}>
                    Schedule Demo
                  </MKButton>
                </Grid>
                <Grid item>
                  <MKButton variant="outlined" color="white" size="large">
                    View Compliance Features
                  </MKButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      <Container sx={{ py: 6 }}>
        {/* Key Challenges Section */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} textAlign="center" mb={4}>
            <MKTypography variant="h2" mb={1}>
              Transform Clinical Trial Management
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Address key challenges in clinical research operations
            </MKTypography>
          </Grid>

          {[
            {
              icon: "speed",
              title: "Accelerate Study Startup",
              description: "Reduce startup time by 60% with automated workflows and digital documentation"
            },
            {
              icon: "verified_user",
              title: "Ensure Compliance* (TBD)",
              description: "Built-in compliance checks for ICH-GCP, 21 CFR Part 11, and HIPAA requirements (coming soon)"
            },
            {
              icon: "people",
              title: "Streamline Enrollment",
              description: "Efficient participant screening and enrollment process management"
            },
            {
              icon: "analytics",
              title: "Real-time Insights",
              description: "Comprehensive analytics and reporting for trial progress and metrics"
            }
          ].map(({ icon, title, description }) => (
            <Grid item xs={12} md={6} lg={3} key={title}>
              <Card sx={{ p: 3, height: "100%" }}>
                <Icon color="info" sx={{ fontSize: 40 }}>{icon}</Icon>
                <MKTypography variant="h5" mt={2} mb={1}>
                  {title}
                </MKTypography>
                <MKTypography variant="body2" color="text">
                  {description}
                </MKTypography>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Digital Workflow Section */}
        <Grid container spacing={6} alignItems="center" mb={6}>
          <Grid item xs={12} md={6}>
            <MKTypography variant="h3" mb={3}>
              End-to-End Clinical Trial Workflow
            </MKTypography>
            <Timeline position="right">
              {[
                {
                  title: "Participant Screening",
                  description: "Digital pre-screening forms with automated eligibility checks",
                  icon: "person_search"
                },
                {
                  title: "Informed Consent",
                  description: "Electronic informed consent with audit trails and version control",
                  icon: "fact_check"
                },
                {
                  title: "Document Collection",
                  description: "Secure document upload and verification system",
                  icon: "description"
                },
                {
                  title: "Study Coordination",
                  description: "Automated task assignment and progress tracking",
                  icon: "assignment"
                },
                {
                  title: "Data Integration",
                  description: "Seamless integration with EDC and CTMS systems",
                  icon: "sync"
                }
              ].map(({ title, description, icon }) => (
                <TimelineItem key={title}>
                  <TimelineSeparator>
                    <TimelineDot color="info">
                      <Icon>{icon}</Icon>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <MKTypography variant="h6">{title}</MKTypography>
                    <MKTypography variant="body2" color="text">
                      {description}
                    </MKTypography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox component="img" src={workflowImage} width="100%" />
          </Grid>
        </Grid>

        {/* Compliance Features */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3, height: "100%" }}>
              <MKTypography variant="h4" mb={3}>
                Comprehensive Compliance
              </MKTypography>
              <List>
                {[
                  // {
                  //   title: "21 CFR Part 11 Compliance",
                  //   items: [
                  //     "Electronic signatures",
                  //     "Audit trails",
                  //     "System validation",
                  //     "Data integrity controls"
                  //   ]
                  // },
                  {
                    title: "HIPAA Compliance (coming soon)",
                    items: [
                      "Data encryption",
                      "Access controls",
                      "PHI protection",
                      "Security monitoring"
                    ]
                  },
                  {
                    title: "ICH-GCP Guidelines",
                    items: [
                      "Protocol adherence",
                      "Documentation management",
                      "Quality control",
                      "Data verification"
                    ]
                  }
                ].map(({ title, items }) => (
                  <MKBox key={title} mb={3}>
                    <MKTypography variant="h6" mb={1}>
                      {title}
                    </MKTypography>
                    {items.map((item) => (
                      <ListItem key={item} dense>
                        <ListItemIcon>
                          <Icon color="success">check_circle</Icon>
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </MKBox>
                ))}
              </List>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3, height: "100%" }}>
              <MKTypography variant="h4" mb={3}>
                Key Platform Features
              </MKTypography>
              <Grid container spacing={2}>
                {[
                  {
                    icon: "dynamic_form",
                    title: "Smart Forms",
                    description: "Dynamic eCRF creation with validation rules"
                  },
                  {
                    icon: "rule",
                    title: "Eligibility Engine",
                    description: "Automated participant screening and eligibility checks"
                  },
                  {
                    icon: "content_copy",
                    title: "Document Management",
                    description: "Version-controlled study documentation"
                  },
                  {
                    icon: "analytics",
                    title: "Real-time Analytics",
                    description: "Comprehensive enrollment and study metrics"
                  },
                  {
                    icon: "integration_instructions",
                    title: "System Integration",
                    description: "Connect with EDC, CTMS, and other systems"
                  },
                  {
                    icon: "security",
                    title: "Security Controls",
                    description: "Role-based access and data protection"
                  }
                ].map(({ icon, title, description }) => (
                  <Grid item xs={12} sm={6} key={title}>
                    <MKBox display="flex" mb={2}>
                      <Icon color="info" sx={{ fontSize: 30, mr: 2 }}>{icon}</Icon>
                      <MKBox>
                        <MKTypography variant="h6">{title}</MKTypography>
                        <MKTypography variant="body2" color="text">
                          {description}
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>
        </Grid>

        {/* Results Section */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} textAlign="center" mb={4}>
            <MKTypography variant="h3">
              Expected Results in Clinical Research
            </MKTypography>
          </Grid>
          {[
            {
              metric: "60%",
              title: "Faster Study Startup",
              description: "Reduce time from protocol approval to first patient enrolled"
            },
            {
              metric: "75%",
              title: "Reduced Admin Time",
              description: "Less time spent on paperwork and manual processes"
            },
            {
              metric: "99.9%",
              title: "Compliance Rate",
              description: "Maintain consistent regulatory compliance"
            },
            {
              metric: "40%",
              title: "Cost Reduction",
              description: "Lower operational costs through automation"
            }
          ].map(({ metric, title, description }) => (
            <Grid item xs={12} md={3} key={title}>
              <Card sx={{ p: 3, height: "100%", textAlign: "center" }}>
                <MKTypography variant="h2" color="info">
                  {metric}
                </MKTypography>
                <MKTypography variant="h5" mt={2} mb={1}>
                  {title}
                </MKTypography>
                <MKTypography variant="body2" color="text">
                  {description}
                </MKTypography>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* CTA Section */}
        <Card
          sx={{
            p: 6,
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${complianceImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={8}>
              <MKTypography variant="h3" mb={1}>
                Ready to Transform Your Clinical Research Operations?
              </MKTypography>
              <MKTypography variant="body1" color="text">
                Join and streamline your clinical trials with CONSEN
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={4} display="flex" justifyContent="center">
              <MKButton variant="gradient" color="info" size="large" onClick={()=>navigate('/contact-us')}>
                Request Demo
              </MKButton>
            </Grid>
          </Grid>
        </Card>
      </Container>


    </PublicLayout>
  );
}

export default ClinicalResearch;