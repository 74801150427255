
import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// MKit React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// MKit React routes
// import routes from "routes";
import siteRoutes from "site-routes";
import ExitIntentProvider, { ExitIntentConfig } from "./context/ExitIntentProvider";
import React from "react";
import ExitIntentDialog from "./pages/common-sections/ExitIntentDialog";
// import HomePage from "./pages/HomePage";
// const customConfig:ExitIntentConfig = {
//   timing: {
//     delay: 500,
//     sessionLimit: 3,
//   },
//   targeting: {
//     urls: ['/pricing', '/features'],
//   },
// };

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ExitIntentProvider >
      <Routes>
        {getRoutes(siteRoutes)}
        {/* <Route path="/home" element={<HomePage />} /> */}
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
        <ExitIntentDialog/>
      </ExitIntentProvider>
    </ThemeProvider>
  );
}
