// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKTypography from "../../../components/MKTypography";

function Outcomes() {
  return (
    <MKBox component="section" py={3} mt={4} mb={16}>
      <Container>
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}>
            <MKTypography variant="h2" fontWeight="bold" mb={0.5}
                          sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                              fontSize: size["2xl"]
                            }
                          })}
            >
              Eliminate Manual Processes. Reduce Costs. Maintain Compliance.
            </MKTypography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              color="error"
              icon="schedule"
              title="Cut Processing Time by 60%"
              description="Transform weeks of manual work into days. Automated workflows eliminate bottlenecks and accelerate business operations without hiring developers."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              color="success"
              icon="savings"
              title="Reduce Operational Costs by 75%"
              description="Stop losing money on manual data entry, document processing, and repetitive tasks. Automate your workflows and redirect resources to growth."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              color="info"
              icon="auto_awesome"
              title="Enhance Customer Experience"
              description="End the frustration of lengthy processes and repeated information requests. Provide a seamless experience that turns customers into advocates."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              color="warning"
              icon="security"
              title="Eliminate Compliance Risks"
              description="Stop worrying about regulatory requirements. Built-in compliance controls and audit trails keep you protected across HIPAA, SOC2, and GDPR."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              color="primary"
              icon="psychology"
              title="Free Your Team's Time"
              description="Give your team back 20+ hours per week. Automated document generation and task management lets them focus on high-value work."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              color="success"
              icon="integration_instructions"
              title="End System Fragmentation"
              description="Stop jumping between disconnected tools. Unified platform connects with your existing systems to create a single source of truth."
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Outcomes;