// pages/use-cases/WorkflowAutomation.js
import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState } from "react";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { useNavigate } from "react-router-dom";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";

// Custom components for workflow visualization
// import WorkflowNodeCard from "components/WorkflowNodeCard";
// import CodeBlock from "components/CodeBlock";

// Images (you'll need these)
import bgImage from "assets/images/cs/cs-bg-2.jpg";
import workflowBuilder from "assets/images/cs/CS-screens/1.png";
import automationMetrics from "assets/images/bg10.jpg";
import PublicLayout from "../../../layouts/PublicLayout";

function WorkflowAutomation() {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  const workflowCategories = {
    CUSTOMER_FACING: ["T&C", "PRE-SCREEN", "ELIGIBILITY", "APPLICATION", "VERIFY"],
    INTERNAL_PROCESS: ["REVIEW", "TASK", "NOTIFY"],
    SYSTEM: ["START", "END", "DECISION", "DATA", "INTEGRATION"],
    DISPLAY: ["TEXT", "INFO"]
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <PublicLayout title={"Automate Workflow, reduce manual work"}>

      {/* Hero Section */}
      <MKBox
        pt={12}
        pb={6}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={8} textAlign="center">
              <MKTypography
                variant="h1"
                color="white"
                mb={3}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Automate Any Business Workflow Without Code
              </MKTypography>
              <MKTypography variant="body1" color="white" mb={3}>
                Transform complex business processes into streamlined digital workflows.
                Reduce manual work by 80% and accelerate processing times by 60%.
              </MKTypography>
              <MKButton variant="gradient" color="info" size="large">
                Start Building Workflows
              </MKButton>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      {/* Main Content */}
      <Container sx={{ py: 6 }}>



        {/* Key Capabilities */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} textAlign="center" mb={4}>
            <MKTypography variant="h2" mb={1}>
              Build. Automate. Scale.
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Everything you need to automate complex business workflows
            </MKTypography>
          </Grid>

          {[
            {
              icon: "architecture",
              title: "Visual Workflow Builder",
              description: "Design complex workflows with drag-and-drop simplicity. No coding required."
            },
            {
              icon: "bolt",
              title: "Intelligent Automation",
              description: "Automate decisions and tasks based on custom business rules and conditions."
            },
            {
              icon: "integration_instructions",
              title: "Seamless Integration",
              description: "Connect with existing systems and third-party services via APIs."
            },
            {
              icon: "insights",
              title: "Real-time Analytics",
              description: "Monitor performance and optimize workflows with detailed insights."
            }
          ].map(({ icon, title, description }) => (
            <Grid item xs={12} md={6} lg={3} key={title}>
              <Card
                sx={{
                  p: 3,
                  height: "100%",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "translateY(-5px)",
                  }
                }}
              >
                <Icon color="info" sx={{ fontSize: 40 }}>{icon}</Icon>
                <MKTypography variant="h5" mt={2} mb={1}>
                  {title}
                </MKTypography>
                <MKTypography variant="body2" color="text">
                  {description}
                </MKTypography>
              </Card>
            </Grid>
          ))}
        </Grid>



        {/* New: Application Builder Section */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} md={6}>
            <MKTypography variant="h3" mb={3}>
              Powerful Application Builder
            </MKTypography>
            <MKTypography variant="body1" mb={3}>
              Create sophisticated data collection forms and workflows using our
              drag-and-drop application builder:
            </MKTypography>
            <Grid container spacing={2}>
              {/* {[ */}
              {/*   { */}
              {/*     icon: "dynamic_form", */}
              {/*     title: "Dynamic Forms (EForm)", */}
              {/*     description: "Create adaptive forms with conditional logic and validation" */}
              {/*   }, */}
              {/*   { */}
              {/*     icon: "upload_file", */}
              {/*     title: "File Upload (MFile)", */}
              {/*     description: "Secure document upload with preview and validation" */}
              {/*   }, */}
              {/*   { */}
              {/*     icon: "text_fields", */}
              {/*     title: "Text Widget", */}
              {/*     description: "Add instructions and dynamic content" */}
              {/*   }, */}
              {/*   { */}
              {/*     icon: "image", */}
              {/*     title: "Image Widget", */}
              {/*     description: "Include visual elements and branding" */}
              {/*   } */}
              {/* ].map(({ icon, title, description }) => ( */}
              {/*   <Grid item xs={12} key={title}> */}
              {/*     <WorkflowNodeCard */}
              {/*       icon={icon} */}
              {/*       title={title} */}
              {/*       description={description} */}
              {/*     /> */}
              {/*   </Grid> */}
              {/* ))} */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
{/*             <CodeBlock */}
{/*               language="javascript" */}
{/*               code={`// Example Application Configuration */}
{/* { */}
{/*   "type": "APPLICATION", */}
{/*   "components": [ */}
{/*     { */}
{/*       "type": "EForm", */}
{/*       "schema": { */}
{/*         "type": "object", */}
{/*         "properties": { */}
{/*           "personalInfo": { */}
{/*             "type": "object", */}
{/*             "properties": { */}
{/*               "firstName": { */}
{/*                 "type": "string", */}
{/*                 "title": "First Name" */}
{/*               } */}
{/*             } */}
{/*           } */}
{/*         } */}
{/*       } */}
{/*     }, */}
{/*     { */}
{/*       "type": "MFile", */}
{/*       "config": { */}
{/*         "allowedTypes": ["pdf", "jpg"], */}
{/*         "maxSize": 5000000 */}
{/*       } */}
{/*     } */}
{/*   ] */}
{/* }`} */}
{/*             /> */}
          </Grid>
        </Grid>



        {/* Enhanced Integration Section */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} textAlign="center" mb={4}>
            <MKTypography variant="h3">
              Enterprise-Grade Integration
            </MKTypography>
          </Grid>
          {[
            {
              icon: "cloud",
              title: "AWS Integration",
              features: [
                "S3 for secure file storage",
                "Lambda for serverless processing",
                "DynamoDB for scalable data storage",
                "Cognito for authentication"
              ]
            },
            {
              icon: "security",
              title: "Security & Compliance",
              features: [
                "Multi-tenant isolation",
                "Role-based access control",
                "Data encryption at rest",
                "Audit logging"
              ]
            },
            {
              icon: "api",
              title: "API Connectivity",
              features: [
                "RESTful API support",
                "Custom API integration",
                "Webhook capabilities",
                "Real-time data sync"
              ]
            }
          ].map(({ icon, title, features }) => (
            <Grid item xs={12} md={4} key={title}>
              <Card sx={{ p: 3, height: "100%" }}>
                <Icon color="info" sx={{ fontSize: 40 }}>{icon}</Icon>
                <MKTypography variant="h5" mt={2} mb={2}>
                  {title}
                </MKTypography>
                {features.map((feature) => (
                  <MKBox key={feature} display="flex" alignItems="center" mb={1}>
                    <Icon color="success" sx={{ fontSize: 20 }}>check_circle</Icon>
                    <MKTypography variant="body2" ml={1}>
                      {feature}
                    </MKTypography>
                  </MKBox>
                ))}
              </Card>
            </Grid>
          ))}
        </Grid>



        {/* Workflow Examples Section */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              centered
              sx={{ mb: 4 }}
            >
              <Tab label="Finance & Banking" />
              <Tab label="HR & Operations" />
              <Tab label="Sales & Marketing" />
              <Tab label="Legal & Compliance" />
            </Tabs>

            <Box hidden={tabValue !== 0}>
              <WorkflowExample
                title="Financial Process Automation"
                workflows={[
                  "Loan Application Processing",
                  "Account Opening Workflow",
                  "Credit Approval Process",
                  "KYC Verification Flow"
                ]}
                metrics={{
                  time: "75% faster processing",
                  cost: "60% cost reduction",
                  accuracy: "99.9% accuracy rate"
                }}
              />
            </Box>

            <Box hidden={tabValue !== 1}>
              <WorkflowExample
                title="HR Process Automation"
                workflows={[
                  "Employee Onboarding",
                  "Leave Request Management",
                  "Performance Review Process",
                  "Training & Compliance"
                ]}
                metrics={{
                  time: "80% faster onboarding",
                  cost: "70% reduced HR workload",
                  accuracy: "100% compliance rate"
                }}
              />
            </Box>

            <Box hidden={tabValue !== 2}>
              <WorkflowExample
                title="Sales Process Automation"
                workflows={[
                  "Lead Qualification",
                  "Quote Generation",
                  "Contract Approval",
                  "Customer Onboarding"
                ]}
                metrics={{
                  time: "65% faster deal closure",
                  cost: "50% reduced admin work",
                  accuracy: "90% higher conversion"
                }}
              />
            </Box>

            <Box hidden={tabValue !== 3}>
              <WorkflowExample
                title="Legal Process Automation"
                workflows={[
                  "Contract Review & Approval",
                  "Compliance Checking",
                  "Document Generation",
                  "Risk Assessment"
                ]}
                metrics={{
                  time: "70% faster processing",
                  cost: "65% cost savings",
                  accuracy: "100% compliance rate"
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* How It Works */}
        <Grid container spacing={6} alignItems="center" mb={6}>
          <Grid item xs={12} md={6}>
            <MKTypography variant="h3" mb={3}>
              Build Workflows in Minutes
            </MKTypography>
            {[
              {
                icon: "drag_indicator",
                title: "Drag & Drop Designer",
                description: "Design workflows visually with our intuitive builder"
              },
              {
                icon: "rule",
                title: "Business Rules Engine",
                description: "Define conditions and automated actions"
              },
              {
                icon: "hub",
                title: "Integration Hub",
                description: "Connect with your existing tools and systems"
              },
              {
                icon: "analytics",
                title: "Performance Analytics",
                description: "Monitor and optimize workflow efficiency"
              }
            ].map(({ icon, title, description }) => (
              <MKBox
                key={title}
                display="flex"
                alignItems="flex-start"
                mb={3}
                sx={{
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "translateX(10px)",
                  }
                }}
              >
                <Icon color="info" sx={{ fontSize: 30, mr: 2 }}>{icon}</Icon>
                <Box>
                  <MKTypography variant="h6" mb={0.5}>
                    {title}
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    {description}
                  </MKTypography>
                </Box>
              </MKBox>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox component="img" src={workflowBuilder} width="100%" />
          </Grid>
        </Grid>

        {/* New: Workflow Components Section */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} textAlign="center" mb={4}>
            <MKTypography variant="h2" mb={1}>
              Comprehensive Workflow Components
            </MKTypography>
            <MKTypography variant="body1" color="text" mb={4}>
              Build end-to-end workflows with our extensive library of components
            </MKTypography>
          </Grid>

          {Object.entries(workflowCategories).map(([category, nodes]) => (
            <Grid item xs={12} md={6} lg={3} key={category}>
              <Card sx={{ p: 3, height: "100%" }}>
                <MKTypography variant="h6" color="info" mb={2}>
                  {category.replace(/_/g, " ")}
                </MKTypography>
                <Grid container spacing={1}>
                  {nodes.map((node) => (
                    <Grid item key={node}>
                      <Tooltip title={getNodeDescription(node)}>
                        <Chip
                          label={node}
                          color="primary"
                          variant="outlined"
                          sx={{ m: 0.5 }}
                        />
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Success Metrics */}
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} textAlign="center" mb={4}>
            <MKTypography variant="h3">
              Proven Results
            </MKTypography>
          </Grid>
          {[
            {
              metric: "80%",
              title: "Reduction in Manual Work",
              description: "Automate repetitive tasks and free up valuable time"
            },
            {
              metric: "60%",
              title: "Faster Processing",
              description: "Accelerate workflow completion times dramatically"
            },
            {
              metric: "90%",
              title: "Cost Savings",
              description: "Reduce operational costs through automation"
            },
            {
              metric: "100%",
              title: "Compliance Rate",
              description: "Ensure consistent policy and regulatory compliance"
            }
          ].map(({ metric, title, description }) => (
            <Grid item xs={12} md={3} key={title}>
              <Card
                sx={{
                  p: 3,
                  height: "100%",
                  textAlign: "center",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "translateY(-5px)",
                  }
                }}
              >
                <MKTypography variant="h2" color="info">
                  {metric}
                </MKTypography>
                <MKTypography variant="h5" mt={2} mb={1}>
                  {title}
                </MKTypography>
                <MKTypography variant="body2" color="text">
                  {description}
                </MKTypography>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* CTA Section */}
        <MKBox
          sx={{
            bgcolor: "grey.100",
            borderRadius: 2,
            p: 6,
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(${automationMetrics})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={8}>
              <MKTypography variant="h3" mb={1}>
                Start Automating Your Workflows Today
              </MKTypography>
              <MKTypography variant="body1" color="text">
                Join thousands of businesses that have transformed their operations with our workflow automation platform
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={4} display="flex" justifyContent="center">
              <MKButton variant="gradient" color="info" size="large">
                Start Free Trial
              </MKButton>
            </Grid>
          </Grid>
        </MKBox>
      </Container>


    </PublicLayout>
  );
}

// Workflow Example Component
function WorkflowExample({ title, workflows, metrics }) {
  return (
    <Card sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <MKTypography variant="h4" mb={3}>
            {title}
          </MKTypography>
          {workflows.map((workflow) => (
            <MKBox key={workflow} display="flex" alignItems="center" mb={2}>
              <Icon color="success" sx={{ mr: 1 }}>check_circle</Icon>
              <MKTypography variant="body1">{workflow}</MKTypography>
            </MKBox>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <MKBox bgcolor="grey.100" p={3} borderRadius="lg">
            <MKTypography variant="h6" mb={2}>
              Achieved Results
            </MKTypography>
            {Object.entries(metrics).map(([key, value]) => (
              <MKBox key={key} display="flex" alignItems="center" mb={1}>
                <Icon color="info" sx={{ mr: 1 }}>trending_up</Icon>
                <MKTypography variant="body2">{value}</MKTypography>
              </MKBox>
            ))}
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}
// Helper function for node descriptions
function getNodeDescription(node) {
  const descriptions = {
    "T&C": "Terms & Conditions acceptance step",
    "PRE-SCREEN": "Initial qualification checks",
    "ELIGIBILITY": "Detailed eligibility verification",
    "APPLICATION": "Data collection forms and file uploads",
    "VERIFY": "Identity and information verification",
    "REVIEW": "Internal review process",
    "TASK": "Manual task assignment",
    "NOTIFY": "Notification triggers",
    // ... add more descriptions
  };
  return descriptions[node] || "Workflow component";
}
export default WorkflowAutomation;