// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// MKit React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function ServiceHighlights() {  // Renamed from SocialAnalytics
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={6}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        >
          <MKTypography variant="h4" color="info" textGradient>
            Why Choose CONSEN
          </MKTypography>
          <MKTypography variant="h2" my={1}>
            Transform Your Operations
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Streamline your customer application process with our innovative platform.
            Built for businesses that value efficiency, compliance, and customer experience.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              color={"success"}
              icon="groups"
              title="Expert Support Team"
              description="Our dedicated team of onboarding specialists is here to help you implement
                and optimize your customer application processes. Get personalized support throughout
                your journey with CONSEN."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              color={"info"}
              icon="support_agent"
              title="24/7 Technical Assistance"
              description="Round-the-clock technical support ensures your application processes run
                smoothly. Our response team is always ready to address any concerns and maintain
                your business continuity."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto" }}>
            <DefaultInfoCard
              color={"warning"}
              icon="update"
              title="Continuous Innovation"
              description="We regularly update our platform with new features and improvements based
                on customer feedback and industry trends. Stay ahead with our evolving solution for
                modern business needs."
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ServiceHighlights;