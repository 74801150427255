import axios from "axios";
import { environment } from "../environments/environment.prod";
import { ExitIntentFormData } from "./exitIntent.service";

export const regApiGatewayUrl = environment.apiGatewayUrl;

const getHTMLMessageBody = (values: any) =>
  Object.entries(values)
    .map(([key, value]) => `
        <div style="margin-bottom: 10px;">
          <strong>${key}:</strong> ${value}
        </div>
      `)
    .join("");

async function sendMessage(messageBody) {
  return await axios.post(regApiGatewayUrl + "sesmail/send", messageBody);
}

async function submitExitIntentForm(data: ExitIntentFormData) {

  const message = {
    Subject: `[CONSEN-L] exit-intent - ${data.name}`,
    HTMLBody: `<html><body>${JSON.stringify(data)}</body></html>`,
    TXTBody: JSON.stringify(data),
  };

  return await sendMessage( message);
}

async function sendEmail(data: { to: string, template: string, data: Record<string, any>; }) {
  // return await axios.post(regApiGatewayUrl + "/api/email/send", data);
  const message = {
    Subject: `[CONSEN-L] exit_intent_welcome - ${data.to}`,
    HTMLBody: `<html><body>${JSON.stringify(data)}</body></html>`,
    TXTBody: JSON.stringify(data),
  };

  return await sendMessage( message);
}

async function trackEvent(data: { eventName: string, properties: Record<string, any> }) {
  // return await axios.post(regApiGatewayUrl + "/api/analytics/track", data);
  // const message = {
  //   Subject: `[CONSEN-L] exit_intent_conversion - ${data?.properties?.company}`,
  //   HTMLBody: `<html><body>${JSON.stringify(data)}</body></html>`,
  //   TXTBody: JSON.stringify(data),
  // };
  //
  // return await sendMessage( message);
}

const CSApiService = {
  sendMessage,
  submitExitIntentForm,
  sendEmail,
  trackEvent,
  getHTMLMessageBody
};


export default CSApiService;