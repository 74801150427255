import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function WhyChoose() {
  return (
    <MKBox component="section" py={{ xs: 6, lg: 12 }}>
      <Container>
        <Grid container alignItems="center" mb={4}>
          <Grid item xs={12} lg={12}>
            <MKTypography variant="h3" mb={2}>
              Industry-Specific Solutions with Proven ROI
            </MKTypography>
            <MKTypography variant="body1" color="text" mb={2}>
              See how organizations like yours can achieve measurable results with Consen
            </MKTypography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon="account_balance"
              color="info"
              title="Financial Services"
              description={
                <>
                  <strong>• 60% faster client onboarding</strong>
                  <br />
                  • Automated KYC/AML compliance*
                  <br />
                  • Secure document collection
                  <br />
                  {/* <em>Used by leading investment firms & banks</em> */}
                </>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon="gavel"
              color="warning"
              title="Legal Services"
              description={
                <>
                  <strong>• 75% reduction in processing time</strong>
                  <br />
                  • Automated client intake
                  <br />
                  • Secure case management
                  <br />
                  {/* <em>Trusted by top law firms</em> */}
                </>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon="health_and_safety"
              color="success"
              title="Healthcare"
              description={
                <>
                  <strong>• 80% faster patient registration</strong>
                  <br />
                  • HIPAA-compliant workflows (coming soon)
                  <br />
                  • Integrated insurance verification
                  <br />
                  {/* <em>Deployed across major healthcare networks</em> */}
                </>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon="business"
              color="error"
              title="Insurance"
              description={
                <>
                  <strong>• 70% faster claim processing</strong>
                  <br />
                  • Automated underwriting workflows*
                  <br />
                  • Real-time policy generation
                  <br />
                  {/* <em>Trusted by leading insurers</em> */}
                </>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon="people"
              color="primary"
              title="Human Resources"
              description={
                <>
                  <strong>• 85% faster onboarding</strong>
                  <br />
                  • Automated compliance tracking
                  <br />
                  • Integrated background checks
                  <br />
                  {/* <em>Used by Fortune 500 HR teams</em> */}
                </>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon="school"
              color="info"
              title="Education"
              description={
                <>
                  <strong>• 65% reduction in admin time</strong>
                  <br />
                  • Streamlined enrollment
                  <br />
                  • Automated record management
                  <br />
                  {/* <em>Trusted by leading institutions</em> */}
                </>
              }
            />
          </Grid>
        </Grid>

        {/* Success Metrics Section */}
        <Grid container spacing={3} mt={6} textAlign="center">
          <Grid item xs={12}>
            <MKTypography variant="h4" mb={4}>
              Overall Customer Success Metrics
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={4}>
            <MKTypography variant="h2" color="info">70%</MKTypography>
            <MKTypography>Average Time Saved</MKTypography>
          </Grid>
          <Grid item xs={12} md={4}>
            <MKTypography variant="h2" color="success">85%</MKTypography>
            <MKTypography>Customer Satisfaction</MKTypography>
          </Grid>
          <Grid item xs={12} md={4}>
            <MKTypography variant="h2" color="warning">3x</MKTypography>
            <MKTypography>ROI in First Year</MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WhyChoose;