import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";

// Types
export interface ExitIntentConfig {
  timing: {
    delay: number;          // Delay before showing popup (ms)
    frequency: number;      // Time between shows (ms)
    sessionLimit: number;   // Max times to show per session
  };
  triggers: {
    mouseLeave: boolean;    // Enable mouse leave detection
    inactivity: number;     // Inactivity timeout (ms)
    backButton: boolean;    // Detect back button press
    scrollDepth: number;    // Scroll depth trigger (0-1)
  };
  targeting: {
    urls: string[];        // URLs to show on
    excludeUrls: string[]; // URLs to exclude
    devices: ("desktop" | "tablet" | "mobile")[];
  };
}

interface ExitIntentState {
  isVisible: boolean;
  triggeredCount: number;
  lastTriggered: number;
  hasConverted: boolean;
  deviceType: "desktop" | "tablet" | "mobile";
}

interface ExitIntentContextType {
  state: ExitIntentState;
  config: ExitIntentConfig;
  showDialog: () => void;
  hideDialog: () => void;
  registerConversion: () => void;
}

// Default configuration
const defaultConfig: ExitIntentConfig = {
  timing: {
    delay: 1000,
    frequency: 24 * 60 * 60 * 1000, // 24 hours
    sessionLimit: 2
  },
  triggers: {
    mouseLeave: true,
    inactivity: 300000, // 5 minutes
    backButton: true,
    scrollDepth: 0.5
  },
  targeting: {
    urls: [],
    excludeUrls: ["/checkout", "/thank-you"],
    devices: ["desktop", "tablet", "mobile"]
  }
};

// Context
const ExitIntentContext = createContext<ExitIntentContextType | undefined>(undefined);

// Reducer
type ExitIntentAction =
  | { type: "SHOW_DIALOG" }
  | { type: "HIDE_DIALOG" }
  | { type: "REGISTER_CONVERSION" }
  | { type: "SET_DEVICE_TYPE"; payload: "desktop" | "tablet" | "mobile" };

function exitIntentReducer(state: ExitIntentState, action: ExitIntentAction): ExitIntentState {
  switch (action.type) {
    case "SHOW_DIALOG":
      return {
        ...state,
        isVisible: true,
        triggeredCount: state.triggeredCount + 1,
        lastTriggered: Date.now()
      };
    case "HIDE_DIALOG":
      return {
        ...state,
        isVisible: false
      };
    case "REGISTER_CONVERSION":
      return {
        ...state,
        hasConverted: true,
        isVisible: false
      };
    case "SET_DEVICE_TYPE":
      return {
        ...state,
        deviceType: action.payload
      };
    default:
      return state;
  }
}

// Provider Component
interface ExitIntentProviderProps {
  children: React.ReactNode;
  config?: Partial<ExitIntentConfig>;
}

export function ExitIntentProvider({ children, config = {} }: ExitIntentProviderProps) {
  const mergedConfig = {
    ...defaultConfig,
    ...config,
    timing: { ...defaultConfig.timing, ...config.timing },
    triggers: { ...defaultConfig.triggers, ...config.triggers },
    targeting: { ...defaultConfig.targeting, ...config.targeting }
  };

  const [state, dispatch] = useReducer(exitIntentReducer, {
    isVisible: false,
    triggeredCount: 0,
    lastTriggered: 0,
    hasConverted: false,
    deviceType: "desktop"
  });

  // Device detection
  const detectDevice = useCallback(() => {
    const width = window.innerWidth;
    if (width < 768) return "mobile";
    if (width < 1024) return "tablet";
    return "desktop";
  }, []);

  // Show/Hide handlers
  const showDialog = useCallback(() => {
    const now = Date.now();
    const canShow =
      !state.hasConverted &&
      state.triggeredCount < mergedConfig.timing.sessionLimit &&
      now - state.lastTriggered >= mergedConfig.timing.frequency;

    if (canShow) {
      dispatch({ type: "SHOW_DIALOG" });
    }
  }, [state.hasConverted, state.triggeredCount, state.lastTriggered, mergedConfig.timing]);

  const hideDialog = useCallback(() => {
    dispatch({ type: "HIDE_DIALOG" });
  }, []);

  const registerConversion = useCallback(() => {
    dispatch({ type: "REGISTER_CONVERSION" });
  }, []);

  // Mouse leave detection
  useEffect(() => {
    if (!mergedConfig.triggers.mouseLeave || state.deviceType !== "desktop") return;

    let timeoutId: NodeJS.Timeout;

    const handleMouseLeave = (e: MouseEvent) => {
      if (e.clientY <= 0) {
        timeoutId = setTimeout(showDialog, mergedConfig.timing.delay);
      }
    };

    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.removeEventListener("mouseleave", handleMouseLeave);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [mergedConfig.triggers.mouseLeave, mergedConfig.timing.delay, state.deviceType, showDialog]);

  // Inactivity detection
  useEffect(() => {
    if (!mergedConfig.triggers.inactivity) return;

    let inactivityTimeout: NodeJS.Timeout;

    const resetInactivityTimer = () => {
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(showDialog, mergedConfig.triggers.inactivity);
    };

    const events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];
    events.forEach(event => document.addEventListener(event, resetInactivityTimer));

    resetInactivityTimer();

    return () => {
      events.forEach(event => document.removeEventListener(event, resetInactivityTimer));
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
    };
  }, [mergedConfig.triggers.inactivity, showDialog]);

  // Device type detection
  useEffect(() => {
    const handleResize = () => {
      dispatch({ type: "SET_DEVICE_TYPE", payload: detectDevice() });
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [detectDevice]);

  // URL targeting
  useEffect(() => {
    const currentPath = window.location.pathname;
    const isExcluded = mergedConfig.targeting.excludeUrls.some(url => currentPath.includes(url));
    const isIncluded = mergedConfig.targeting.urls.length === 0 ||
      mergedConfig.targeting.urls.some(url => currentPath.includes(url));

    if (isExcluded || !isIncluded) {
      hideDialog();
    }
  }, [mergedConfig.targeting, hideDialog]);

  const contextValue = {
    state,
    config: mergedConfig,
    showDialog,
    hideDialog,
    registerConversion
  };

  return (
    <ExitIntentContext.Provider value={contextValue}>
      {children}
    </ExitIntentContext.Provider>
  );
}

// Hook for consuming the context
export function useExitIntent() {
  const context = useContext(ExitIntentContext);
  if (context === undefined) {
    throw new Error("useExitIntent must be used within an ExitIntentProvider");
  }
  return context;
}

export default ExitIntentProvider;